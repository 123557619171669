import '../styles/global.scss';

import React from 'react'
import css from './chartAwesomeLogo.module.scss';

export default function ChartAwesomeLogo({showTitle}) {
    return (
        <div className={`${css.logo}`}>
            <img src="/logo.svg" alt="logo" className="app-logo"/>
            {
                showTitle && <h1><span style={{color: '#d9a03e'}}>Chart</span> <span style={{color: '#d97474'}}>Awesome</span></h1>
            }
        </div>
    )
}