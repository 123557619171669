import Dexie from 'dexie';
import TEXT from '../utils/constant';
import relationships from 'dexie-relationships';

// console.log('inside Dexie Service');

const db = new Dexie('ChartAwesome', {addons: [relationships]})

db.version(1).stores({
    workspaces: 'id, name',
    datasources: 'id, name, type, workspaceId -> workspaces.id',
    questions: 'id, workspaceId -> workspaces.id',
    dashboards: 'id, workspaceId -> workspaces.id'
});

db.open().catch(function (e) {
    console.error("Dexie DB error: " + e);
});

function updateDatasource(data, action) {
    // console.log('Updating Datasource');
    switch (action) {
        // Add and Edit are being handled in the same call. Hence using PUT
        case TEXT.DEXIE_ACTION_ADD:
            db.transaction('rw', db.datasources, db.workspaces, () => {
                db.datasources.put({...data.datasource, workspaceId: data.workspaceId}, data.datasource.id);
                db.workspaces.where('id').equals(data.workspaceId).modify(workspace => {
                    workspace.datasources.push(data.datasource.id);
                    workspace.datasources = [...new Set(workspace.datasources)];
                });
            });
            return;
        case TEXT.DEXIE_ACTION_DELETE:
            db.transaction('rw', db.datasources, db.workspaces, () => {
                db.datasources.delete(data.datasourceId);
                db.workspaces.where('id').equals(data.workspaceId).modify(workspace => {
                    workspace.datasources = workspace.datasources.filter(datasourceId => datasourceId !== data.datasourceId)
                });
            });
            return;
        default:
            return;
    }
}

function updateQuestion(data, action) {
    // console.log('Updating Questions');
    switch (action) {
        // Add and Edit are being handled in the same call. Hence using PUT
        case TEXT.DEXIE_ACTION_ADD:
            db.transaction('rw', db.questions, db.workspaces, () => {
                db.questions.put({...data.question, workspaceId: data.workspaceId}, data.question.id);
                db.workspaces.where('id').equals(data.workspaceId).modify(workspace => {
                    workspace.questions.push(data.question.id);
                    workspace.questions = [...new Set(workspace.questions)];
                });
            });
            return;
        case TEXT.DEXIE_ACTION_DELETE:
            // Also update all the dashboards, which have this question
            db.transaction('rw', db.questions, db.workspaces, db.dashboards, () => {
                db.questions.delete(data.questionId);
                db.workspaces.where('id').equals(data.workspaceId).modify(workspace => {
                    workspace.questions = workspace.questions.filter(questionId => questionId !== data.questionId)
                });
                db.dashboards.where('workspaceId').equals(data.workspaceId).modify(dashboard => {
                    dashboard.questionIds = dashboard.questionIds.filter(questionId => questionId !== data.questionId);
                    delete dashboard.layout[data.questionId];
                });
            });
            return;
        default:
            return;
    }
}


function updateBulkQuestion(data) {
    // console.log('Bulk Updating Questions');
    const _questions = data.questionsToUpdate.map(question => {
        return {...question, workspaceId: data.workspaceId}
    })
    db.questions.bulkPut(_questions).then(function(lastKey) {
        // console.log("Done Updating Questions with last Key ", lastKey);
    }).catch(Dexie.BulkError, function (e) {
        // Explicitely catching the bulkAdd() operation makes those successful additions commit despite that there were errors.
        console.error ("Some questions were not updated. However, " +
            data.questionsToUpdate.length - e.failures.length + " were updated successfully");
    });
}

function updateDashboard(data, action) {
    // console.log('Updating Dashboards');
    switch (action) {
        // Add and Edit are being handled in the same call. Hence using PUT
        case TEXT.DEXIE_ACTION_ADD:
            db.transaction('rw', db.dashboards, db.workspaces, () => {
                db.dashboards.put({...data.dashboard, workspaceId: data.workspaceId}, data.dashboard.id);
                db.workspaces.where('id').equals(data.workspaceId).modify(workspace => {
                    workspace.dashboards.push(data.dashboard.id);
                    workspace.dashboards = [...new Set(workspace.dashboards)];
                });
            });
            return;
        case TEXT.DEXIE_ACTION_DELETE:
            db.transaction('rw', db.dashboards, db.workspaces, () => {
                db.dashboards.delete(data.dashboardId);
                db.workspaces.where('id').equals(data.workspaceId).modify(workspace => {
                    workspace.dashboards = workspace.dashboards.filter(dashboardId => dashboardId !== data.dashboardId)
                });
            });
            return;
        default:
            return;
    }
}

function updateWorkspace(data, action) {
    // console.log('Updating Workspace');
    switch (action) {
        case TEXT.DEXIE_ACTION_ADD:
            // Simply adding won't work, since for workspaces coming from files, these would be fully loaded, and will need to break them.
            const workspace = data.workspace;
            const datasourceIds = [];
            const questionIds = [];
            const dashboardIds = [];

            const _datasources = workspace.datasources.map(datasource => {
                datasourceIds.push(datasource.id);
                return {...datasource, workspaceId: data.workspace.id}
            });
            const _questions = workspace.questions.map(question => {
                questionIds.push(question.id);
                return {...question, workspaceId: data.workspace.id}
            });
            const _dashboards = workspace.dashboards.map(dashboard => {
                dashboardIds.push(dashboard.id);
                return {...dashboard, workspaceId: data.workspace.id}
            });

            workspace.datasources = datasourceIds;
            workspace.questions = questionIds;
            workspace.dashboards = dashboardIds;

            db.workspaces.put(workspace, data.workspace.id);
            if (_datasources.length > 0) {
                db.datasources.bulkPut(_datasources);
            }
            
            if (_questions.length > 0) {
                db.questions.bulkPut(_questions);
            }

            if (_dashboards.length > 0) {
                db.dashboards.bulkPut(_dashboards);
            }
            
            return;
        case TEXT.DEXIE_ACTION_EDIT:
            db.workspaces.update(data.workspaceId, {
                name: data.name,
                description: data.description
            });
            return;
        case TEXT.DEXIE_ACTION_DELETE:
            db.workspaces.delete(data.workspaceId);
            // Would also need to remove all joined datasources, questions & dashboards.
            return;
        default:
            return;
    }
}

async function getSavedWorkspaces() {
    let allWorkspaces = await db.workspaces.toArray();
    let docsWorkspaceExists = false;
    const allWorkspacesPromises = allWorkspaces.map(async workspace => {
        const datasourceIds = workspace.datasources;
        const questionIds = workspace.questions;
        const dashboardIds = workspace.dashboards;

        const results = await Promise.allSettled([db.datasources.bulkGet(datasourceIds), db.questions.bulkGet(questionIds), db.dashboards.bulkGet(dashboardIds)])
        const keyArray = ['datasources', 'questions', 'dashboards'];
        results.forEach((result, index) => {
            // here this result will be an array
            // console.log(result);
            if (result.status === 'fulfilled') {
                workspace[keyArray[index]] = result.value.filter(_result => _result !== undefined);
            } else if (result.status === 'rejected') {
                workspace[keyArray[index]] = [];
            }
        });
        
        if (workspace.id === 'docs-example-workspace') {
            docsWorkspaceExists = true;
        }

        return workspace;
    });

    allWorkspaces = await Promise.all(allWorkspacesPromises);
    // console.log(allWorkspaces);
    return {workspaces: allWorkspaces, docsWorkspaceExists};
}


export { 
    updateDatasource, 
    updateQuestion, 
    updateBulkQuestion,
    updateDashboard, 
    updateWorkspace,
    getSavedWorkspaces
}