import 'bulma';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <App />
    </Router>
  </React.StrictMode>, container
);

// ReactDOM.render(
//   <Router>
//       <App />
//   </Router>, container
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(ConsoleHelper))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
