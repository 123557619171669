const TEXT = {
    EXCEL_DATASOURCE: 'excel',
    TABLE_DATASOURCE: 'table',
    BULK_DATASOURCE: 'bulk',

    CUSTOM_TEMPLATE_TYPE: 'custom',
    STANDARD_TEMPLATE_TYPE: 'standard',

    VIEW_STATE_DASHBOARD: 'dashboard',
    VIEW_STATE_EDITOR: 'editor',

    DEFAULT_DATE_FORMAT: 'dd-MMM',

    MULTIPLE_COLUMNS_MULTIPLE_ROWS: 'mcmr',
    SINGLE_COLUMN_MULTIPLE_ROWS: 'scmr',
    SINGLE_COLUMN_SINGLE_COLUMN: 'scsc',
    SINGLE_COLUMN_MULTIPLE_COLUMNS: 'scmc',

    NOTHING: 'nothing',
    SHOW_RELATED_COLUMNS: 'showRelatedColumns',

    CATEGORY_DATA_TYPE: 'category',
    NUMBER_DATA_TYPE: 'number',
    DATE_DATA_TYPE: 'date',

    NOT_AVAILABLE: 'N.A',

    ONLY: 'only',
    MERGE: 'merge',
    ALL: 'all',

    SECOND_COLUMN: 'secondColumn',

    CHART_TABLE: 'tableChart',
    HIERARCHY: 'hierarchy',

    QUICK_CHART_LINE: 'line',
    QUICK_CHART_AREA: 'area',
    QUICK_CHART_PIE: 'pie',
    QUICK_CHART_DONUT: 'donut',
    QUICK_CHART_VERTICAL: 'vertical',
    QUICK_CHART_HORIZONTAL: 'horizontal',
    QUICK_CHART_SCATTER: 'scatter',

    REQUIRED: 'required',
    IS_MULTIPLE: 'multiple',
    IS_NUMBER: 'number',

    CHART_PROVIDER_RECHARTS: 'recharts',
    CHART_PROVIDER_VISX: 'visx',
    CHART_PROVIDER_CUSTOM: 'chartAwesome',

    DEXIE_ACTION_ADD: 'add',
    DEXIE_ACTION_EDIT: 'edit',
    DEXIE_ACTION_DELETE: 'delete',

    IS_CHART_AWESOME_FILE: 'Is_ChartAwesome_file',
    IS_CHART_AWESOME_TEXT: 'This is a ChartAwesome Workspace',
    INVALID_LOAD_FILE: 'Invalid ChartAwesome workspace file.'
} 

export default TEXT;


// I can be without you, but i choose to be with you.
// Inhale with your mouth slightly open, from 10ft and then close your mouth, and even though you are exhaling via your nose, think you are doing it via your third eye upto 20ft, and go on increasing the distance. Essentially you are growing 
// Become a full moon for all around you
// Moon cycle, before 4.5 years hit the sun cycle