import ChartAwesomeLogo from "./ChartAwesomeLogo";
import { Link } from "react-router-dom";

export default function Navbar() {
    return (
        <nav className="navbar" role="navigation" aria-label="main navigation" style={{height : '60px'}}>
            <div className="container is-fluid">
                <div className="navbar-brand">
                    <Link to="/" className="navbar-item">
                        <ChartAwesomeLogo showTitle='true'></ChartAwesomeLogo> 
                    </Link>
                </div>
                <div id="navbarMenuHeroA" className="navbar-menu">
                    <div className="navbar-end">
                        <Link to="/quickCreate" className="navbar-item">
                            <u>Quick Create</u><sup className="is-size-7 has-text-success" style={{marginTop: '-8px', marginLeft: '4px'}}>NEW</sup>
                        </Link>
                        <Link to="/studies" className="navbar-item">
                            Case Story
                        </Link>
                        <Link to="/docs" className="navbar-item">
                            Docs
                        </Link>
                        <Link to="/workspaces" className="navbar-item">
                            Workspaces
                        </Link>
                        <Link to="/feedback" className="navbar-item">
                            Feedback
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}