import './App.scss';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Suspense, lazy } from 'react';

import Feedback from './components/feedback/Feedback';
import Navbar from './components/Navbar';
import PageNotFound from './components/PageNotFound';
import { ToastContainer } from 'react-toastify';
import WorkspaceContextProvider from './context/workspaceContext';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons/faArrowsAlt';
import {faCamera} from '@fortawesome/free-solid-svg-icons/faCamera';
import {faChalkboard} from '@fortawesome/free-solid-svg-icons/faChalkboard';
import {faChartBar} from '@fortawesome/free-solid-svg-icons/faChartBar';
import {faChartLine} from '@fortawesome/free-solid-svg-icons/faChartLine';
import {faChartPie} from '@fortawesome/free-solid-svg-icons/faChartPie';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {faClone} from '@fortawesome/free-solid-svg-icons/faClone';
import {faCog} from '@fortawesome/free-solid-svg-icons/faCog';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import {faEdit} from '@fortawesome/free-solid-svg-icons/faEdit';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {faFlag} from '@fortawesome/free-solid-svg-icons/faFlag';
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import {faLink} from '@fortawesome/free-solid-svg-icons/faLink';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons/faLinkedin';
import {faMagnet} from '@fortawesome/free-solid-svg-icons/faMagnet';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {faPercentage} from '@fortawesome/free-solid-svg-icons/faPercentage';
import {faPlug} from '@fortawesome/free-solid-svg-icons/faPlug';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faPlusSquare} from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faSun} from '@fortawesome/free-solid-svg-icons/faSun';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {faTable} from '@fortawesome/free-solid-svg-icons/faTable';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faTools} from '@fortawesome/free-solid-svg-icons/faTools';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {faUpload} from '@fortawesome/free-solid-svg-icons/faUpload';
import { library } from '@fortawesome/fontawesome-svg-core';

const About = lazy(() => import('./components/about'));
const CaseStory = lazy(() => import('./components/caseStories/CaseStory'));
const Documentation = lazy(() => import('./components/documentation/documentation'));
const Landing = lazy(() => import('./components/landing'));
const QuickCreate = lazy(() => import('./components/quickOne/QuickCreate'));
const Workspace = lazy(() => import('./components/workspace/Workspace'));
const WorkspaceList = lazy(() => import('./components/workspace/WorkspaceList'));

const Timeline = lazy(() => import('./components/timeline/Timeline'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));

library.add(faLinkedin, faUpload, faCheckSquare, faCheck, faCog, faChevronDown, faChevronUp, faEdit, faTrash, faHome, faSave, faTimes, faInfoCircle, faTable, faExclamationTriangle, faEye,
  faChartBar, faChartLine, faChartPie, faSun, faMagnet, faQuestionCircle, faPercentage, faTools, faChalkboard, faPlus, faPlusSquare, faTrashAlt, faClone, faMinus, faLink, faSync, faCamera, faArrowsAlt, faFlag, faPlug, faAngleDown, faAngleUp, faDownload);

const coverStyle = {
  position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px', backgroundColor: '#dbdbdb66'}


console.log('<<<< Chart Awesome');

const DefaultLoader = () => {
  return (
    <div style = {{position: 'absolute', zIndex: 2}}>
      <div style = {coverStyle}></div>
      <div style = {{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px'
      }}>
        Loading...
      </div>
    </div>
  )
}


const HomeRoute = () => {
  let { path } = useRouteMatch();

  return(
    <>
      <Navbar></Navbar>
      <Switch>
        <Route path={`${path}`} exact={true} component={Landing} />
        <Route path={`${path}studies`} component={CaseStory} />
        <Route path={`${path}docs`} component={Documentation} />
        <Route path={`${path}timeline`} component={Timeline} />
        <Route path={`${path}privacy-policy`} component={PrivacyPolicy} />
        <Route path={`${path}feedback`} component={Feedback} />
        <Route path={`${path}workspaces`} component={WorkspaceList} />
        <Route path={`${path}quickCreate`} component={QuickCreate} />
        <Route component={PageNotFound} />
      </Switch>
      <About></About>
    </>
  );
};


function App() {
  return (
      <WorkspaceContextProvider>
        <Suspense fallback={<DefaultLoader />}>
          <Switch>
            <Route path='/workspace/:id' component={Workspace} />
            <Route path='/' component={HomeRoute} />
          </Switch>
        </Suspense>
        <ToastContainer/>
      </WorkspaceContextProvider>
  );
}

export default App;