import TEXT from "../utils/constant";

function categoryDataCheck(obj) {
    const check = obj.categoryValues && obj.categoryValues !== '' && obj.valueInterpreter === TEXT.ALL;
    return check ? `Change to <b>${TEXT.MERGE}</b> or <b>${TEXT.ONLY}</b> for the data selectors, and click Save again.` : '';
}

function numberDataCheck(obj) {
    const check = !obj.numberValues || 
        (obj.numberValues && obj.numberValues.valueLevel === 'some' && obj.numberValues.valueGroups.length === 0);
    return check ? `Please provide the number groups, and click Save again.` : '';
}


function thresholdCheck(value) {
    let numberValue = value;
    const percentIndex = value.indexOf('%');
    if (percentIndex !== -1) {
        numberValue = parseInt(value.substring(0, percentIndex)) || 0;
    }

    return isNaN(numberValue) ? 'Reference value, if specified must be a &lt;value&gt; OR &ltvalue%&gt;' : '';
}


function xAxisChecker(selections, parsedXAxisColumns, mappingType) {
    let xAxisErrors = [];
    const xAxis = selections.xAxis;
    if (!xAxis || !xAxis.selector) {
        xAxisErrors.push('Selector is missing.');
    } else {
        // parsedXAxisColumns is a Map
        if (!parsedXAxisColumns || (parsedXAxisColumns && parsedXAxisColumns.length === 0)) {
            xAxisErrors.push('No valid selectors that can be read.');
        } else {
            if (mappingType === TEXT.MULTIPLE_COLUMNS_MULTIPLE_ROWS) {
                if (parsedXAxisColumns.size <= 1) {
                    xAxisErrors.push('Length of selectors must be greater than <b>1</b>, for this mapping type.');
                } 
            } else if (mappingType !== TEXT.MULTIPLE_COLUMNS_MULTIPLE_ROWS) {
                if (parsedXAxisColumns.size === 0 || parsedXAxisColumns.size > 1) {
                    xAxisErrors.push('Length of columns must be equal to <b>1</b>, for this mapping type.');
                }
            }
        }

        if(!xAxis.dataType) {
            xAxisErrors.push('Data Type is missing.');
        } else {
            xAxisErrors.push(xAxis.dataType === TEXT.CATEGORY_DATA_TYPE ? categoryDataCheck(xAxis) : 
                xAxis.dataType === TEXT.NUMBER_DATA_TYPE ? numberDataCheck(xAxis) : '');
        }
    }

    xAxisErrors = xAxisErrors.filter(error => error !== '');
    return xAxisErrors;
}


function secondColumnChecker(selections, parsedYAxisColumns, columnLength) {
    let secondColumnErrors = [];
    const secondColumn = selections.secondColumn;

    // It is either an array or a map
    const selectedColumnsLength = Array.isArray(parsedYAxisColumns) ? parsedYAxisColumns.length : parsedYAxisColumns.size;

    if (!secondColumn || !secondColumn.selector) {
        secondColumnErrors.push('Selector is missing.');
    } else {
        if (!parsedYAxisColumns || (parsedYAxisColumns && parsedYAxisColumns.length === 0)) {
            secondColumnErrors.push('No valid selectors that can be read.');
        } else {
            if (columnLength === 'many') {
                if (selectedColumnsLength <= 1) {
                    secondColumnErrors.push('Length of selectors must be greater than <b>1</b>, for this mapping type.');
                } 
            } else if (columnLength === 'one'){
                if (selectedColumnsLength === 0 || selectedColumnsLength > 1) {
                    secondColumnErrors.push('Length of columns must be equal to <b>1</b>, for this mapping type.');
                }
            }
        }

        if(!secondColumn.dataType) {
            secondColumnErrors.push('Data Type is missing.');
        } else {
            secondColumnErrors.push(secondColumn.dataType === TEXT.CATEGORY_DATA_TYPE ? categoryDataCheck(secondColumn) : 
                secondColumn.dataType === TEXT.NUMBER_DATA_TYPE ? numberDataCheck(secondColumn) : '');
        }
    }

    secondColumnErrors = secondColumnErrors.filter(error => error !== '');
    return secondColumnErrors;
}


// function extentChecker(selections, parsedYAxisColumns) {
//     let extentErrors = [];
//     const extent = selections.extent;
//     if (!extent || !extent.selector) {
//         extentErrors.push('Selector is missing.');
//     }

//     return extentErrors;
// }


function additionalsChecker(additionals) {
    let additionalErrors = [];
    const referenceValue = additionals?.referenceValue;

    if (referenceValue && referenceValue !== '' && referenceValue !== 0) {
        additionalErrors.push(thresholdCheck(referenceValue));
    }

    additionalErrors = additionalErrors.filter(error => error !== '');
    return additionalErrors;
}


// Multiple columns multiple rows
function mappingType_mcmr_validity(selections, parsedXAxisColumns, parsedYAxisColumns) {
    const xAxisErrors = xAxisChecker(selections, parsedXAxisColumns, TEXT.MULTIPLE_COLUMNS_MULTIPLE_ROWS);    
    // const extentErrors = extentChecker(selections, parsedYAxisColumns);
    const additionalErrors = additionalsChecker(selections.additionals);
    
    return {xAxisErrors, additionalErrors};
}


function mappingType_scmr_validity(selections, parsedXAxisColumns, parsedYAxisColumns) {
    const xAxisErrors = xAxisChecker(selections, parsedXAxisColumns, TEXT.SINGLE_COLUMN_MULTIPLE_ROWS);  
    // const extentErrors = extentChecker(selections, parsedYAxisColumns);
    const additionalErrors = additionalsChecker(selections.additionals);

    return {xAxisErrors, additionalErrors};
}


function mappingType_scsc_validity(selections, parsedXAxisColumns, parsedYAxisColumns) {
    const xAxisErrors = xAxisChecker(selections, parsedXAxisColumns, TEXT.SINGLE_COLUMN_MULTIPLE_ROWS);  
    const secondColumnErrors = secondColumnChecker(selections, parsedYAxisColumns, 'one'); 
    // const extentErrors = extentChecker(selections);
    const additionalErrors = additionalsChecker(selections.additionals);

    return {xAxisErrors, secondColumnErrors, additionalErrors};
}


function mappingType_scmc_validity(selections, parsedXAxisColumns, parsedYAxisColumns) {
    const xAxisErrors = xAxisChecker(selections, parsedXAxisColumns, TEXT.SINGLE_COLUMN_MULTIPLE_ROWS);  
    const secondColumnErrors = secondColumnChecker(selections, parsedYAxisColumns, 'many'); 
    // const extentErrors = extentChecker(selections);
    const additionalErrors = additionalsChecker(selections.additionals);

    return {xAxisErrors, secondColumnErrors, additionalErrors};
}


//*************************** MAIN METHOD ****************************//
export function isValidSelections(selections, rawData, parsedXAxisColumns, parsedYAxisColumns) {
    const mappingType = selections.mappingType;
    const validationErrors = {
        overall: [],
        totalErrors: 0
    };

    if (rawData.length === 0) {
        validationErrors.overall.push('No matching rows found to process');
    }

    let errorDetails = {};
    if (mappingType === TEXT.MULTIPLE_COLUMNS_MULTIPLE_ROWS) {
        errorDetails = mappingType_mcmr_validity(selections, parsedXAxisColumns, parsedYAxisColumns);
    } else if (mappingType === TEXT.SINGLE_COLUMN_MULTIPLE_ROWS) {
        errorDetails = mappingType_scmr_validity(selections, parsedXAxisColumns, parsedYAxisColumns);
    } else if (mappingType === TEXT.SINGLE_COLUMN_SINGLE_COLUMN) {
        errorDetails = mappingType_scsc_validity(selections, parsedXAxisColumns, parsedYAxisColumns);
    } else if (mappingType === TEXT.SINGLE_COLUMN_MULTIPLE_COLUMNS) {
        errorDetails = mappingType_scmc_validity(selections, parsedXAxisColumns, parsedYAxisColumns);
    }

    validationErrors.totalErrors = validationErrors.overall.length + (errorDetails.xAxisErrors?.length || 0)
        + (errorDetails.extentErrors?.length || 0) + (errorDetails.additionalErrors?.length || 0) + (errorDetails.secondColumnErrors?.length || 0);
    return {...validationErrors, xAxis: errorDetails.xAxisErrors || [], extent: errorDetails.extentErrors || [], 
        additionals: errorDetails.additionalErrors || [], secondColumn: errorDetails.secondColumnErrors || []}
}