import { Link } from "react-router-dom";

export default function PageNotFound() {
    return (
        <section className="hero has-background-warning-light is-fullheight">
            <div className="hero-body is-flex-direction-column is-justify-content-center">
                <div className="block">
                    <h1 className="title is-1">404</h1>
                </div>
                <p className="subtitle">
                    <Link to="/" className="button is-info is-large">HOME</Link>
                </p>
            </div>
        </section>
    )
}