//@ts-check

import React, { useReducer } from 'react';
import { aesGcmDecrypt, aesGcmEncrypt } from '../services/EncryptionService';
import { columnSelectorParser, interpretQuestionPropertiesExcel, interpretQuestionPropertiesRawData, interpretQuestionPropertiesTable, randomIdGenerator, readDataFromExcel, readDataFromRawData, readDataFromTable, rowSelectorParser, saveTemplateAsFile, settingTableSelectorMaps } from '../utils/utility';
import {getSavedWorkspaces, updateBulkQuestion, updateDashboard, updateDatasource, updateQuestion, updateWorkspace} from "../services/DexieService";

import TEXT from '../utils/constant';
import { isValidSelections } from '../services/ValidationService';
import { messageService } from '../services/MessageService';

export const WorkspaceContext = React.createContext();

let XLSX = null;
const docsExampleWorkspace = {
    id: 'docs-example-workspace',
    name: 'Docs Examples Workspace',
    datasources: [],
    questions: [],
    dashboards: [],
    deleteAllowed: false
};


const initialState = {
    loading: false,
    error: null,
    showAbout: false,
    isInitialized: false,

    selectedWorkspace: '',
    workspaces: []
};

async function getInitialState() {
    // First read the state of Dexie. If workspaces are found, populate them and set them as initial state.
    // Then if one of the workspace is - docs-example-workspace, then do not else set it and return
    const {workspaces, docsWorkspaceExists} = await getSavedWorkspaces();
    if (!docsWorkspaceExists) {
        workspaces.push(docsExampleWorkspace);
        updateWorkspace({workspace: docsExampleWorkspace}, TEXT.DEXIE_ACTION_ADD);
    }

    return workspaces;
}

function reducer(state, action) {
    let questionState = '';
    const payload = action.payload;

    let newState;
    let objectToAdd;
    let modifiedQuestion;

    let questionsToUpdate = [];

    switch(action.type) {
        case 'INITIAL_STATE_UPDATE':
            newState = {...state, 
                workspaces: action.payload,
                isInitialized: true
            };
            // console.log('Initial State Updated');
            return newState;

        case 'TOGGLE_ABOUT':
            return {...state, showAbout : action.payload}

        case 'FILE_CHOSEN':
            const file = action.payload.f;
            const datasourceAdded = {id : action.payload.id, data: action.payload.data, name: file.name, file: file, type: action.payload.type};
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === state.selectedWorkspace) {
                        workspace.datasources =  [...workspace.datasources, datasourceAdded]
                    }
                    return workspace;
                })
            };
            updateDatasource({datasource: datasourceAdded, workspaceId: state.selectedWorkspace}, TEXT.DEXIE_ACTION_ADD);
            return newState;

        case 'TABLE_DATA_ENTERED':
        case 'BULK_DATA_ENTERED':
            objectToAdd = {
                id : action.payload.id, data: action.payload.data, name: action.payload.name, 
                    type: action.payload.type, availableFields: action.payload.availableFields || []
            };
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === action.payload.workspaceId) {
                        const newArray = [...workspace.datasources]; //making a new array
                        const index = newArray.findIndex(datasource => datasource.id === action.payload.id ); //finding index of the item

                        if (index !== -1) {
                            newArray[index] = objectToAdd
                        } else {
                            newArray.push(objectToAdd);
                        }
                        workspace.datasources =  newArray;
                    }
                    return workspace;
                })
            };
            updateDatasource({datasource: objectToAdd, workspaceId: action.payload.workspaceId}, TEXT.DEXIE_ACTION_ADD);
            return newState;

        case 'DELETE_DATASOURCE': 
            questionsToUpdate = [];
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === state.selectedWorkspace) {
                        workspace.datasources =  workspace.datasources.filter(datasource => datasource.id !== action.payload);
                        workspace.questions = workspace.questions.map(question => {
                            if (question.datasource && question.datasource.value === action.payload) {
                                delete question.datasource;
                                delete question.rawData;
                                question.parsed = '';
                                questionsToUpdate.push(question);                             
                            }
                            return question;
                        });
                    }
                    return workspace;
                })
            };
            messageService.sendMessage({text : 'Datasource deleted successfully !!', status: 'success'});
            updateDatasource({datasourceId: action.payload, workspaceId: state.selectedWorkspace}, TEXT.DEXIE_ACTION_DELETE);
            updateBulkQuestion({questionsToUpdate: questionsToUpdate, workspaceId: payload.workspaceId});
            return newState;

        case 'ADD_QUESTION':
            const newQuestion = {
                id: randomIdGenerator(8), 
                text: 'New Question',
                chartType: '',
                chartWidth: '',
                chartHeight: '',
                showInDashboard: false,
                properties: { }
            };
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === action.payload.workspaceId) {
                        workspace.questions = [].concat(workspace.questions, newQuestion);                        
                        // messageService.sendMessage({type : 'questionAdded', question: newQuestion});
                    }
                    return workspace;
                })
            }
            updateQuestion({question: newQuestion, workspaceId: action.payload.workspaceId}, TEXT.DEXIE_ACTION_ADD);
            return newState;

        case 'DELETE_QUESTION':
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === action.payload.workspaceId) {
                        workspace.questions = workspace.questions.filter(question => question.id !== action.payload.questionId);
                    }
                    return workspace;
                })
            }
            updateQuestion({questionId: action.payload.questionId, workspaceId: action.payload.workspaceId}, TEXT.DEXIE_ACTION_DELETE);
            return newState;
        
        case 'QUESTION_EDITED':
        case 'SHOW_IN_DASHBOARD':
            modifiedQuestion = null;
            const workspaceId = action.payload.workspaceId || state.selectedWorkspace;
            delete action.payload.workspaceId;
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === workspaceId) {
                        workspace.questions = workspace.questions.map(question => {
                            if (question.id === action.payload.id) {
                                delete action.payload.id;
                                questionState = {...question, ...action.payload};
                                // console.log(questionState);
                                modifiedQuestion = questionState;
                                return questionState;
                            } else {
                                return question;
                            }
                        });
                    }
                    return workspace;
                })
            }
            if (modifiedQuestion) {
                updateQuestion({question: modifiedQuestion, workspaceId: workspaceId}, TEXT.DEXIE_ACTION_ADD);
            }
            return newState;
        
        /**
         * questionDetails - will be an object with id : {followGlobal, sheetName, extent, dataType}
         * payload - will have questionDetails, datasource, global : {sheetName, extent, dataType}
         */
        case 'QUESTIONS_LINKED':
            questionsToUpdate = [];
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === payload.workspaceId) {
                        workspace.questions = workspace.questions.map(question => {
                            const newDetails = payload.questionDetails[question.id];
                            if (newDetails) {
                                question.datasource = payload.datasource;
                                if (payload.datasource.type === 'excel') {
                                    question.sheetName = newDetails.followGlobal ? payload.global?.sheetName : newDetails.sheetName
                                }

                                // Row Selector goes in Extent
                                if (question.properties && !question.properties.extent) {
                                    question.properties.extent = {};
                                }
                                question.properties.extent.selector = newDetails.followGlobal ? payload.global?.extent : newDetails.extent;

                                // Data Type is for XAxis
                                if (question.properties && !question.properties.xAxis) {
                                    question.properties.xAxis = {};
                                }
                                question.properties.xAxis.dataType = newDetails.followGlobal ? payload.global?.dataType : newDetails.dataType;

                                // Resetting the parsed status
                                question.parsed = '';
                                questionsToUpdate.push(question);
                            }

                            return question;
                        });
                    }
                    return workspace;
                })
            }
            // Take questionsToUpdate and persist it with Dexie
            updateBulkQuestion({questionsToUpdate: questionsToUpdate, workspaceId: payload.workspaceId});
            return newState;

        case 'ADD_WORKSPACE':
            newState = {...state, 
                // workspaces: [...state.workspaces, action.payload.newWorkspace]
                workspaces: [...state.workspaces.filter(existingWorkspace => existingWorkspace.id !== action.payload.newWorkspace.id),  action.payload.newWorkspace]
            };
            updateWorkspace({workspace: action.payload.newWorkspace}, TEXT.DEXIE_ACTION_ADD);
            return newState;

        case 'REMOVE_WORKSPACE':
            newState = {...state, 
                // workspaces: [...state.workspaces, action.payload.newWorkspace]
                workspaces: state.workspaces.filter(existingWorkspace => existingWorkspace.id !== action.payload.workspaceId)
            };
            updateWorkspace({workspaceId: action.payload.workspaceId}, TEXT.DEXIE_ACTION_DELETE);
            return newState;

        case 'SAVE_WORKSPACE_EDITS':
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    // if (workspace.id === state.selectedWorkspace) {
                    if (workspace.id === action.payload.workspaceId) {
                        workspace.name = action.payload.name;
                        workspace.description = action.payload.description;
                    }
                    return workspace;
                })
            };
            updateWorkspace({workspaceId: action.payload.workspaceId, name: action.payload.name, description: action.payload.description}, TEXT.DEXIE_ACTION_EDIT);
            return newState;

        case 'ADD_TO_EXAMPLE_WORKSPACE':
            objectToAdd = {id : action.payload.id, data: action.payload.data, name: action.payload.name, 
                type: action.payload.type, availableFields: action.payload.availableFields || []};
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === 'docs-example-workspace') {
                        const newArray = [...workspace.datasources]; //making a new array
                        const index = newArray.findIndex(datasource => datasource.name === action.payload.name); //finding index of the item

                        if (index !== -1) {
                            newArray[index] = objectToAdd
                        } else {
                            newArray.push(objectToAdd);
                        }
                        workspace.datasources =  newArray;
                    }
                    return workspace;
                })
            };
            messageService.sendMessage({status: 'success'}); 
            updateDatasource({datasource: objectToAdd, workspaceId: 'docs-example-workspace'}, TEXT.DEXIE_ACTION_ADD);
            return newState;

        case 'WORKSPACE_SELECTED':
            return {...state, 
                selectedWorkspace: action.payload.id
            };

        case 'SAVE_DASHBOARD':
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === action.payload.workspaceId) {
                        workspace.dashboards = [...workspace.dashboards.filter(dashboard => dashboard.id !== action.payload.id), 
                            {...action.payload.data, id : action.payload.id}];
                    }
                    return workspace;
                })
            };
            updateDashboard({dashboard: {...action.payload.data, id : action.payload.id}, workspaceId: action.payload.workspaceId}, TEXT.DEXIE_ACTION_ADD);
            return newState;

        case 'DELETE_DASHBOARD':
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === action.payload.workspaceId) {
                        workspace.dashboards = workspace.dashboards.filter(dashboard => dashboard.id !== action.payload.id);
                    }
                    return workspace;
                })
            };
            updateDashboard({dashboardId: action.payload.id, workspaceId: action.payload.workspaceId}, TEXT.DEXIE_ACTION_DELETE);
            return newState;

        case 'LAYOUT_CHANGED':
            let modifiedDashboard;
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === action.payload.workspaceId) {
                        workspace.dashboards = workspace.dashboards.map(dashboard => {
                            if (dashboard.id === action.payload.dashboardId) {
                                dashboard.layout = dashboard.layout ?? {}
                                dashboard.questionIds.forEach(questionid => {
                                    if (action.payload.layout[questionid]) {
                                        dashboard.layout[questionid] = action.payload.layout[questionid]
                                    }
                                });
                                modifiedDashboard = dashboard;
                            } 
                            return dashboard;
                        })
                    }
                    return workspace;
                })
            };
            updateDashboard({dashboard: modifiedDashboard, workspaceId: action.payload.workspaceId}, TEXT.DEXIE_ACTION_ADD);
            return newState;

        case 'UPDATE_CHART_OPTIONS':
            modifiedQuestion = null;
            newState = {...state, 
                workspaces: state.workspaces.map(workspace => {
                    if (workspace.id === state.selectedWorkspace) {
                        workspace.questions = workspace.questions.map(question => {
                            if (question.id === action.payload.questionId) {
                                delete action.payload.questionId;
                                questionState = {...question, ...action.payload};
                                // console.log(questionState);
                                modifiedQuestion = questionState;
                                return questionState;
                            } else {
                                return question;
                            }
                        });
                    }
                    return workspace;
                })
            }
            if (modifiedQuestion) {
                updateQuestion({question: modifiedQuestion, workspaceId: workspaceId}, TEXT.DEXIE_ACTION_ADD);
            }
            return newState;

        default:
            return state;
    }
}


function useDataManager() {
    const [state, dispatch] = useReducer(reducer, initialState);

    function updateStateFromLocal(workspaces) {
        dispatch({
            type:'INITIAL_STATE_UPDATE',
            payload: workspaces
        })
    }

    function readFile(e) {
        e.preventDefault();
        const files = e.target.files;
        const f = files[0];

        let reader = new FileReader();
        reader.readAsBinaryString(f);

        reader.onload = async function (e) {
            let data = e.target.result;

            const datasourceId = randomIdGenerator(8);
            dispatch({
                type: 'FILE_CHOSEN',
                payload: {data, f, type: 'excel', id: datasourceId}
            });
            messageService.sendMessage({datasourceId: datasourceId});

            XLSX = await import('xlsx');
        }
    }

    const saveTableData = (data) => {
        const name = data.name;
        delete data.name;

        const id = data.id;
        delete data.id;

        const workspaceId = data.workspaceId;
        delete data.workspaceId;

        dispatch({
            type: 'TABLE_DATA_ENTERED',
            payload: {data, type: 'table', name, id, availableFields: data.headers, workspaceId}
        });
        messageService.sendMessage({datasourceId: id});
    }

    const saveTableToExampleWorkspace = (data, callback) => {
        const name = data.name;
        delete data.name;

        const id = data.id;
        delete data.id;

        dispatch({
            type: 'ADD_TO_EXAMPLE_WORKSPACE',
            payload: {data, type: 'table', name, id, availableFields: data.headers}
        });
        callback('Datasource successfully added to <b>Docs Examples Workspace</b>. Navigate to Workspaces, to view and work with this data.', 'success')
    }

    const saveBulkData = (data) => {
        const name = data.name;
        delete data.name;

        const id = data.id;
        delete data.id;

        const workspaceId = data.workspaceId;
        delete data.workspaceId;

        dispatch({
            type: 'BULK_DATA_ENTERED',
            payload: {data: data.details, availableFields: data.availableFields, type: 'bulk', name, id, workspaceId}
        });
        messageService.sendMessage({datasourceId: id});
    }

    const deleteDatasource = (datasourceId) => {
        dispatch({
            type: 'DELETE_DATASOURCE',
            payload: datasourceId
        });
    }

    const addQuestion = (id) => {
        dispatch({
            type: 'ADD_QUESTION',
            payload: {workspaceId: id}
        });
    }

    const deleteQuestion = (questionId, workspaceId) => {
        dispatch({
            type: 'DELETE_QUESTION',
            payload: {questionId: questionId, workspaceId: workspaceId} 
        });
    }

    function showInDashboard(id, status, workspaceId) {
        dispatch({
            type: 'SHOW_IN_DASHBOARD',
            payload: {id: id, showInDashboard: status, workspaceId}
        })
    }

    function questionParametersUpdated(id, values, workspaceId) {
        if (values.chartType) {
            dispatch({
                type: 'QUESTION_EDITED',
                payload: {id: id, ...values, workspaceId}
            });
        } else {
            dispatch({
                type: 'QUESTION_EDITED',
                payload: {id: id, ...values, parsed: '', workspaceId}
            });
        }
        
    }

    function setSelectedWorkspace(id) {
        dispatch({
            type: 'WORKSPACE_SELECTED',
            payload: {id: id}
        })
    }

    function getDatasourceDetails(datasourceId) {
        const selectedWorkspace = getWorkspace(state.selectedWorkspace);
        const datasourceFound =  selectedWorkspace.datasources.find(datasource => datasource.id === datasourceId) || {};
        return {id: datasourceFound.id, name: datasourceFound.name, type: datasourceFound.type};
    }

    function getAvailableData(datasourceId) {
        const selectedWorkspace = getWorkspace(state.selectedWorkspace);
        const datasourceFound =  selectedWorkspace.datasources?.find(datasource => datasource.id === datasourceId) || {};

        let rowsLength = 0;
        if (datasourceFound.type === TEXT.TABLE_DATASOURCE) {
            rowsLength = datasourceFound.data.rows.length;
        } else if (datasourceFound.type === TEXT.BULK_DATASOURCE) {
            rowsLength = datasourceFound.data.rows;
        }

        return {availableFields: datasourceFound.availableFields, rows: rowsLength};
    }

    function getSelectedQuestion(questionId) {
        const selectedWorkspace = getWorkspace(state.selectedWorkspace);
        const questionFound =  selectedWorkspace.questions?.find(question => question.id === questionId) || {};
        return questionFound;
    }

    async function understandingSelection(id, selection, dataType, callback) {
        const selectedWorkspace = getWorkspace(state.selectedWorkspace);
        const question = selectedWorkspace.questions.find(question => question.id === id) || {};

        const selectedDatasource = selectedWorkspace.datasources.find(_datasource => _datasource.id === question.datasource.value);
        const data = selectedDatasource.data;

        const extentSelector = question.properties.extent?.selector || '';
        const rowsArray = rowSelectorParser(extentSelector);

        if (question.datasource && question.datasource.type === TEXT.EXCEL_DATASOURCE) {
            try {
                XLSX = await import('xlsx');
                let readData = XLSX.read(data, {type: 'binary', sheets: question.sheetName});
                const ws = readData.Sheets[question.sheetName];               

                const selectorsArr = selection?.split(',') || [];
                const selectorsMap = columnSelectorParser(selectorsArr, ws);

                try {
                    const { dataParse } = readDataFromExcel(ws, rowsArray, selectorsMap);
                    if (dataType === TEXT.CATEGORY_DATA_TYPE) {
                        const allCategories = getUniqueCategories(dataParse);
                        callback(allCategories);
                    } else if(dataType === TEXT.NUMBER_DATA_TYPE) {
                        const numbersRange = getNumbersRange(dataParse);
                        callback(numbersRange);
                    }

                } catch(err) {
                    console.log(err);
                }
            } catch(err) {
                console.log(err);
            };

        } else if (question.datasource && question.datasource.type === TEXT.TABLE_DATASOURCE) {
            const selectorsArr = selection.map(selectedItem => selectedItem.label);
            const selectorsMap = settingTableSelectorMaps(selectorsArr, data.headers);

            const rowsArray = [...Array(data.rows.length).keys()].map(key=> key += 1);

            try {
                const { dataParse } = readDataFromTable(data, rowsArray, selectorsMap);
                if (dataType === TEXT.CATEGORY_DATA_TYPE) {
                    const allCategories = getUniqueCategories(dataParse);
                    callback(allCategories);
                } else if(dataType === TEXT.NUMBER_DATA_TYPE) {
                    const numbersRange = getNumbersRange(dataParse);
                    callback(numbersRange);
                }
                
            } catch(err) {
                console.log(err);
            }

        } else if (question.datasource && question.datasource.type === TEXT.BULK_DATASOURCE) {
            const selectorsArr = selection.map(selectedItem => selectedItem.label);
            const selectorsMap = new Map();
            selectorsArr.forEach(columnName => {
                selectorsMap.set(columnName, columnName);
            });

            const parsedData = JSON.parse(data.value);
            const rowsArray = [...Array(data.rows).keys()].map(key=> key += 1);

            try {
                const { dataParse } = readDataFromRawData(parsedData, rowsArray, selectorsMap);
                if (dataType === TEXT.CATEGORY_DATA_TYPE) {
                    const allCategories = getUniqueCategories(dataParse);
                    callback(allCategories);
                } else if(dataType === TEXT.NUMBER_DATA_TYPE) {
                    const numbersRange = getNumbersRange(dataParse);
                    callback(numbersRange);
                }
                
            } catch(err) {
                console.log(err);
            }
        }


        function getUniqueCategories(dataParse) {
            const categorySet = new Set();
            const rawData = dataParse.map(entry => Object.fromEntries(entry));
            // console.log(rawData); // - rawData is an array of objects, where each column in the xAxis is a key.
            rawData.forEach(row => {
                Object.values(row).forEach(value => {
                    if (Array.isArray(value)) {
                        value.forEach(item => categorySet.add(item))
                    } else {
                        categorySet.add(value);
                    }
                });
            });

            // console.log(categorySet);
            return [...categorySet]
        }


        function getNumbersRange(dataParse) {
            const numbersList = [];
            const rawData = dataParse.map(entry => Object.fromEntries(entry));
            // console.log(rawData); // - rawData is an array of objects, where each column in the xAxis is a key.
            rawData.forEach(row => {
                Object.values(row).forEach(value => {
                    numbersList.push(value);
                })
            });

            numbersList.sort((a,b) => a-b);
            const length = numbersList.length;

            const median = length % 2 === 1 ?  numbersList[(length / 2) -.5 + 1] : ( numbersList[length / 2] + numbersList[length / 2 + 1]) / 2;
            return {min: numbersList[0], max: numbersList[length - 1], median: Number.parseFloat(median).toFixed(2), uniqueCount: new Set(numbersList).size};
        }
    }

    async function parseData({id, selections, callback}) {
        const selectedWorkspace = getWorkspace(state.selectedWorkspace);
        const question = selectedWorkspace.questions.find(question => question.id === id) || {};

        const selectedDatasource = selectedWorkspace.datasources.find(_datasource => _datasource.id === question.datasource.value);
        const data = selectedDatasource.data;

        // Map selections for Hierarchy to the established format
        if (question.chartType === TEXT.HIERARCHY) {
            selections = transformSelectionsFromHierarchy(selections, selectedDatasource);
        }

        if (question.datasource && question.datasource.type === TEXT.EXCEL_DATASOURCE) {
            try {
                XLSX = await import('xlsx');
                let readData = XLSX.read(data, {type: 'binary', sheets: question.sheetName});
                const ws = readData.Sheets[question.sheetName];

                if (ws) {
                    parseDatasourceExcel({
                        ws,
                        chartType: question.chartType,
                        selections: selections,
                        questionId: id,
                        callback: callback
                    });
                } else {
                    // call 'callback' here with an error
                }
            } catch(err) {
                console.log(err);
            };

        } else if (question.datasource && question.datasource.type === TEXT.TABLE_DATASOURCE) {
            parseDatasourceTable({
                data,
                chartType: question.chartType,
                selections: selections,
                questionId: id,
                callback: callback
            })
        } else if (question.datasource && question.datasource.type === TEXT.BULK_DATASOURCE) {
            parseDatasourceRawData({
                data,
                chartType: question.chartType,
                selections: selections,
                questionId: id,
                callback: callback
            })
        }
    }

    async function parseDatasourceExcel({ws, chartType, selections, questionId, callback}) {
        // First lets parse the details from the sheet for this question
        // and then set the properties and the data on to the questions in the state.
        try {
            const {selectorColumns, rawData, viewByOptions, unitClickFields, yAxisColumns, showViewBy} = 
                interpretQuestionPropertiesExcel(chartType, selections, ws);

            const validationErrors = isValidSelections(selections, rawData, selectorColumns, yAxisColumns);
            console.log(validationErrors);
            
            // if (validationErrors && validationErrors.totalErrors > 0) {
            //     callback({...validationErrors, id: questionId});
            // }
            callback({...validationErrors, id: questionId});
            
            // console.log('Dispatching Question selections');
            if (validationErrors.totalErrors === 0) {
                dispatch ({
                    type: 'QUESTION_EDITED',
                    payload: {rawData, viewByOptions, unitClickFields, yAxisColumns, 
                        properties: selections, id: questionId, showViewBy, selectorColumns, parsed: 'success'}
                });
                messageService.setQuestionEditingStatus({status: 'success', type: 'parse'});
            }
        } catch(err) {
            console.log(err);
            dispatch ({
                type: 'QUESTION_EDITED',
                payload: {properties: selections, 
                    id: questionId, parsed: 'error'}
            })
        }
    }

    function parseDatasourceTable({data, chartType, selections, questionId, callback}) {
        try {
            const {selectorColumns, rawData, viewByOptions, unitClickFields, yAxisColumns, showViewBy} = 
                interpretQuestionPropertiesTable(chartType, selections, data);

            const validationErrors = isValidSelections(selections, rawData, selectorColumns, yAxisColumns);
            console.log(validationErrors);

            // if (validationErrors && validationErrors.totalErrors > 0) {
            //     callback({...validationErrors, id: questionId});
            // }
            if (validationErrors.totalErrors === 0) {
                dispatch ({
                    type: 'QUESTION_EDITED',
                    payload: {rawData, viewByOptions, unitClickFields, yAxisColumns, 
                        properties: selections, id: questionId, showViewBy, selectorColumns, parsed: 'success'}
                });
                messageService.setQuestionEditingStatus({status: 'success', type: 'parse'});
            }

            callback({...validationErrors, id: questionId});
        } catch(err) {
            console.log(err);
            dispatch ({
                type: 'QUESTION_EDITED',
                payload: {properties: selections, 
                    id: questionId, parsed: 'error'}
            });

            const validationErrors = {
                overall: [err.message],
                totalErrors: 1
            };

            callback({...validationErrors, id: questionId});
        }
    }

    function parseDatasourceRawData({data, chartType, selections, questionId, callback}) {
        try {
            const {selectorColumns, rawData, viewByOptions, unitClickFields, yAxisColumns, showViewBy} = 
                interpretQuestionPropertiesRawData(chartType, selections, data);

            const validationErrors = isValidSelections(selections, rawData, selectorColumns, yAxisColumns);
            console.log(validationErrors);

            // if (validationErrors && validationErrors.totalErrors > 0) {
            //     callback({...validationErrors, id: questionId});
            // }
            callback({...validationErrors, id: questionId});
            
            if (validationErrors.totalErrors === 0) {
                dispatch ({
                    type: 'QUESTION_EDITED',
                    payload: {rawData, viewByOptions, unitClickFields, yAxisColumns, 
                        properties: selections, id: questionId, showViewBy, selectorColumns, parsed: 'success'}
                });
                messageService.setQuestionEditingStatus({status: 'success', type: 'parse'});
            }
        } catch(err) {
            console.log(err);
            dispatch ({
                type: 'QUESTION_EDITED',
                payload: {properties: selections, 
                    id: questionId, parsed: 'error'}
            })
        }
    }

    function transformSelectionsFromHierarchy(selections, datasource) {
        /**
         * selections here would be
         * {
            "general": {
                "extent": "3_65"
            },
            "hierarchy": {
                "levels": [
                        {"selector": "B2",  "format": "FNO",  "id": "2FMH"},
                        {"selector": "C2",  "format": "LNI",  "id": "4DNA"},
                        {"selector": "D2",  "format": "FNO",  "id": "OWJ4"}
                    ]
                }
            }
            Which should be mapped to extent - selector - under selections
            and the selector in levels in hierarchy should be mapped to viewBy under additionals, comma separated
         */
        
        if (datasource.type === TEXT.EXCEL_DATASOURCE) {
            if (selections.general && selections.general.extent) {
                selections.extent = {
                    selector: selections.general.extent
                }
            }
            
            if (selections.hierarchy && selections.hierarchy.levels.length > 0) {
                const selectors = [];
                selections.hierarchy.levels.forEach(level => {
                    selectors.push(level.selector);
                });
                selections.additionals = {
                    unitClick: TEXT.SHOW_RELATED_COLUMNS,
                    unitClickRelatedColumns: selectors.join(",")
                }
            }
        } else {
            selections.extent = {
                selector: `1_${datasource.data?.rows.length}`
            }

            if (selections.hierarchy && selections.hierarchy.levels.length > 0) {
                const selectors = [];
                let unitClickRelatedColumns = [];
                selections.hierarchy.levels.forEach(level => {
                    if (level.selector[0]) {
                        selectors.push(level.selector[0].value);
                        unitClickRelatedColumns = [...unitClickRelatedColumns, ...level.selector]
                    }
                });

                selections.additionals = {
                    unitClick: TEXT.SHOW_RELATED_COLUMNS,
                    unitClickRelatedColumns: unitClickRelatedColumns
                }
            }
        }

        // Do not delete anything
        return selections;
    }

    function toggleAbout(bool) {
        dispatch({
            type: 'TOGGLE_ABOUT',
            payload: bool
        });
    }

    function loadWorkspace(details, callback) {
        let reader = new FileReader();
        reader.readAsBinaryString(details.file);

        reader.onload = async function (e) {
            let workspaceRead = e.target.result;
            if (details.hasEncryption) {
                workspaceRead = await aesGcmDecrypt(workspaceRead, details.encryptionPassphrase);
            }

            try {
                workspaceRead = JSON.parse(workspaceRead);
                // console.log(workspaceRead);

                if (workspaceRead[TEXT.IS_CHART_AWESOME_FILE] && workspaceRead[TEXT.IS_CHART_AWESOME_FILE] === TEXT.IS_CHART_AWESOME_TEXT) {
                    delete workspaceRead[TEXT.IS_CHART_AWESOME_FILE];
                    dispatch({
                        type: 'ADD_WORKSPACE',
                        payload: {newWorkspace: workspaceRead}
                    });
                    callback('success');
                } else {
                    callback('error', TEXT.INVALID_LOAD_FILE);
                }

            } catch(err) {
                callback('error', TEXT.INVALID_LOAD_FILE);
            }
        }
    }

    function addWorkspace(workspace) {
        dispatch({
            type: 'ADD_WORKSPACE',
            payload: {newWorkspace: workspace}
        });
    }

    function deleteWorkspace(workspaceId) {
        dispatch({
            type: 'REMOVE_WORKSPACE',
            payload: {workspaceId: workspaceId}
        });
    }

    function saveWorkspaceEdits(details) {
        dispatch({
            type: 'SAVE_WORKSPACE_EDITS',
            payload: {...details}
        });
    }

    async function downloadWorkspace(data, callback) {
        const workspaceToSave = state.workspaces.find(workspace => workspace.id === data.workspaceId) || {};

        const dontCopyKeys = ['parsed', 'rawData', 'viewByOptions', 'unitClickFields', 'yAxisColumns', 'showViewBy', 'selectorColumns'];
        const _questions = workspaceToSave.questions.map(question => {
            const _question = {};
            Object.keys(question).forEach(key => {
                if (!dontCopyKeys.includes(key)) {
                    _question[key] = question[key];
                }
            });

            return _question;
        });

        const _datasources = workspaceToSave.datasources.filter(datasource => data.datasourceIds.indexOf(datasource.id) !== -1);

        const _workspaceDataToSave = {};
        Object.keys(workspaceToSave).forEach(key => {
            if (key === 'questions') {
                _workspaceDataToSave['questions'] = _questions;
            } else if(key === 'datasources') {
                _workspaceDataToSave['datasources'] = _datasources;
            } else {
                _workspaceDataToSave[key] = workspaceToSave[key];
            }
        });

        // Field added to ensure that this is a Chart Awesome Workspace
        _workspaceDataToSave[TEXT.IS_CHART_AWESOME_FILE] = TEXT.IS_CHART_AWESOME_TEXT;

        // console.log(_workspaceDataToSave);
        const stringText = JSON.stringify(_workspaceDataToSave);

        if (data.addEncryption) {
           const encryptedText = await aesGcmEncrypt(stringText, data.encryptionPassphrase);
           saveTemplateAsFile(`${_workspaceDataToSave.name}`, encryptedText, 'txt', callback);
        } else {
            saveTemplateAsFile(`${_workspaceDataToSave.name}`, stringText, 'json', callback);
        }
    }

    function linkDatasourceToQuestions({questionDetails, datasource, global, workspaceId}) {
        dispatch({
            type: 'QUESTIONS_LINKED',
            payload: {questionDetails, datasource, global, workspaceId}
        });
    }

    function getWorkspace(id) {
        return state.workspaces.find(workspace => workspace.id === id) || {};
    }

    function getActiveDatasources(workspaceId, minimal) {
        const workspaceIdToCheck = workspaceId ?? state.selectedWorkspace;
        let datasources = (state.workspaces.find(workspace => workspace.id === workspaceIdToCheck) || {}).datasources || [];
        if (minimal) {
            datasources = datasources.map(datasource => {
                return {
                    name: datasource.name,
                    type: datasource.type,
                    id: datasource.id
                }
            })
        }
        return datasources;
    }

    function getAllWorkspaces() {
        return state.workspaces.map(workspace => {
            return {
                name: workspace.name,
                id: workspace.id
            }
        })
    }

    function getAllQuestionsForDashboard(questionIds, workspaceId) {
        // Only the questions for which showInDashboard is true
        const workspaceIdToCheck = workspaceId ?? state.selectedWorkspace;
        let dashboardQuestions = [];
        if (workspaceIdToCheck) {
            let questions = (state.workspaces.find(workspace => workspace.id === workspaceIdToCheck) || {}).questions || [];
            dashboardQuestions = questions.filter(question => question.showInDashboard && questionIds.indexOf(question.id) !== -1);
        }

        return dashboardQuestions;
    }

    function saveDashboard(data) {
        dispatch({
            type: 'SAVE_DASHBOARD',
            payload: {...data}
        });
    }

    function deleteDashboard(dashboardId, workspaceId) {
        dispatch({
            type: 'DELETE_DASHBOARD',
            payload: {id: dashboardId, workspaceId}
        });
    }

    function saveLayoutChanges(workspaceId, dashboardId, layout) {
        const layoutMap = {};
        layout.forEach(widget => {
            layoutMap[widget.i] = {
                chartWidth: widget.w,
                chartHeight: widget.h,
                x: widget.x,
                y: widget.y
            }
        });
        dispatch({
            type: 'LAYOUT_CHANGED',
            payload: {layout: layoutMap, dashboardId, workspaceId}
        });
    }

    function updateChartOptions(questionId, chartOptions, chartProvider, callback) {
        dispatch({
            type: 'UPDATE_CHART_OPTIONS',
            payload: {questionId, chartOptions, chartProvider}
        });
        callback('Question updated successfully.', 'success');
    }

    return {state, dispatch, updateStateFromLocal, readFile, saveTableData, saveTableToExampleWorkspace, saveBulkData, deleteDatasource, getActiveDatasources, getAllWorkspaces, linkDatasourceToQuestions, showInDashboard, questionParametersUpdated, understandingSelection, parseData, getDatasourceDetails, getAvailableData, getSelectedQuestion, toggleAbout, setSelectedWorkspace, loadWorkspace, addWorkspace, deleteWorkspace, saveWorkspaceEdits, downloadWorkspace, getWorkspace, addQuestion, deleteQuestion, getAllQuestionsForDashboard, saveDashboard, deleteDashboard, saveLayoutChanges, updateChartOptions}; 
} 


export default function WorkspaceContextProvider({children}) {
    const {state, dispatch, updateStateFromLocal, readFile, saveTableData, saveTableToExampleWorkspace, saveBulkData, deleteDatasource, toggleAbout, getActiveDatasources, getAllWorkspaces, linkDatasourceToQuestions, showInDashboard, questionParametersUpdated, understandingSelection, parseData, getDatasourceDetails, getAvailableData, getSelectedQuestion, setSelectedWorkspace, loadWorkspace, addWorkspace, deleteWorkspace, saveWorkspaceEdits, downloadWorkspace, getWorkspace, addQuestion, deleteQuestion, getAllQuestionsForDashboard, saveDashboard, deleteDashboard, saveLayoutChanges, updateChartOptions} = useDataManager();

    if (!state.isInitialized) {
        getInitialState().then(workspaces => {
            updateStateFromLocal(workspaces);
        });
    }
    
    const provider = { 
        state,  
        dispatch,
        readFile,
        saveTableData,
        saveTableToExampleWorkspace,
        saveBulkData,
        deleteDatasource,
        toggleAbout,
        showInDashboard,
        questionParametersUpdated,
        understandingSelection,
        parseData,
        getDatasourceDetails,
        getAvailableData,
        getSelectedQuestion,
        setSelectedWorkspace,
        loadWorkspace,
        addWorkspace,
        deleteWorkspace,
        saveWorkspaceEdits,
        downloadWorkspace,
        getWorkspace,
        getActiveDatasources,
        getAllWorkspaces,
        linkDatasourceToQuestions,
        addQuestion,
        deleteQuestion,
        getAllQuestionsForDashboard,
        saveDashboard,
        deleteDashboard,
        saveLayoutChanges,
        updateChartOptions
    }; 

    return (
        <WorkspaceContext.Provider value={provider}>
            {children}
        </WorkspaceContext.Provider>
    )
}
