import { Subject } from 'rxjs';
import { toast } from 'react-toastify';

const subject = new Subject();

function showToast(message, type) {
    function CustomMessage() {
        return (
            <p dangerouslySetInnerHTML={{ __html: message }} className="is-size-6"></p>
        )
    }

    toast[type](CustomMessage, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}


export const messageService = {
    sendMessage: message => subject.next(message), // message would be an object
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable(),
    showToast: showToast,

    setQuestionEditingStatus: message => subject.next(message), // message would be an object
    getQuestionEditingStatus: () => subject.asObservable()
};