import TEXT from "./constant";

const vizTypes = {
    [TEXT.QUICK_CHART_VERTICAL] : { id: 1, label: 'Vertical Bar', value: TEXT.QUICK_CHART_VERTICAL, imgSrc: '/chartIcons/vertical_bar.png', width: 12, height: 6},
    [TEXT.QUICK_CHART_HORIZONTAL] : { id: 2, label: 'Horizontal Bar', value: TEXT.QUICK_CHART_HORIZONTAL, imgSrc: '/chartIcons/horizontal_bar.png', width: 12, height: 6},

    [TEXT.QUICK_CHART_PIE] : { id: 3, label: 'Pie', value: TEXT.QUICK_CHART_PIE, imgSrc: '/chartIcons/pie.png', width: 6, height: 8},
    [TEXT.QUICK_CHART_DONUT] : { id: 4, label: 'Donut', value: TEXT.QUICK_CHART_DONUT, imgSrc: '/chartIcons/donut.png', width: 6, height: 8},

    [TEXT.QUICK_CHART_LINE] : { id: 5, label: 'Line', value: TEXT.QUICK_CHART_LINE, imgSrc: '/chartIcons/line.png', width: 12, height: 6},
    [TEXT.QUICK_CHART_AREA] : { id: 6, label: 'Area', value: TEXT.QUICK_CHART_AREA, imgSrc: '/chartIcons/area.png', width: 12, height: 6},

    [TEXT.CHART_TABLE]: { id: 7, label: 'Table', value: TEXT.CHART_TABLE, icon: 'table', width: 12, height: 8},
    [TEXT.HIERARCHY] : { id: 8, label: 'Hierarchy', value: TEXT.HIERARCHY, icon: 'sun', width: 6, height: 8},

    // [TEXT.QUICK_CHART_SCATTER] : { id: 9, label: 'ScatterPlot', value: TEXT.QUICK_CHART_SCATTER, imgSrc: '/chartIcons/scatter_plot.png', width: 12, height: 6},
};


function multiSelectorToArray(value) {
    const rows = [];
    const valueParts = value.split('_');
    for(let i = parseInt(valueParts[0]); i <= parseInt(valueParts[1]); i++) {
        rows.push(i);
    }

    return rows;
}


function rowSelectorParser(selector) {
    let selectorRows = [];
    const selectorParts = selector.split(",");

    const rowsToBeDeleted = [];
    selectorParts.forEach(part => {
        part = part.trim();
        // console.log(part);
        
        if(part.startsWith('-')) {
            if(part.includes('_')) {
                rowsToBeDeleted.push(...multiSelectorToArray(part.slice(1)));
            } else {
                rowsToBeDeleted.push(parseInt(part.slice(1)));
            }
        } else if(part.includes('_')) {
            selectorRows.push(...multiSelectorToArray(part));
        } else {
            selectorRows.push(parseInt(part));
        }
    });

    selectorRows = selectorRows.filter(( el ) => !rowsToBeDeleted.includes( el ) )
    return selectorRows;
}


function generateLetters(startSelector, endSelector) {
    let startObject = getColumnInitial(startSelector.trim());
    const startLetter = startObject.columnName;

    let endObject = getColumnInitial(endSelector.trim());
    const endLetter = endObject.columnName;

    const generatedLetters = [];
    const alphabets = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
    // generate letters from start to end - say E to AI

    let nextGeneratedLetter = startLetter;

    // Take the start letter - and get its index
    let startIndex = -1;

    let prefixLetter = '';
    if (startLetter.length > 1) {
        prefixLetter = startLetter.substring(0, startLetter.length - 1);
        const lastLetter = startLetter.split('').pop();
        startIndex = alphabets.indexOf(lastLetter);
    } else {
        startIndex = alphabets.indexOf(startLetter);
    }

    generatedLetters.push(startLetter);
    let nextLetterIndex = startIndex + 1;

    let count = 0;
    while(nextGeneratedLetter !== endLetter) {
        // rotate back if nearing the end
        if (nextLetterIndex > alphabets.length - 1) {
            nextLetterIndex = nextLetterIndex % alphabets.length;
            prefixLetter += 'A';
        } 
        
        nextGeneratedLetter = prefixLetter + alphabets[nextLetterIndex];
        nextLetterIndex += 1;

        // console.log(nextGeneratedLetter, endLetter);
        generatedLetters.push(nextGeneratedLetter);

        // Circuit Breaker
        count++;
        if (count > 20000) {
            break;
        }
    }

    return {generatedLetters, originalLetters : generatedLetters.map(letter => letter + startObject.numberPart)};
}


// Handles a single selector
function getColumnInitial(singleSelector) {
    const columnInitial = [];
    const numberInitial = [];
   
    const singleSelectorParts = singleSelector.split('');
    let i = 0;
    while(i < singleSelectorParts.length) {
        if (!isNaN(parseInt(singleSelectorParts[i]))) {
            break;
        }
        columnInitial.push(singleSelectorParts[i])
        i += 1;
    }

    i = 0;
    while(i < singleSelectorParts.length) {
        if (!isNaN(parseInt(singleSelectorParts[i]))) {
            numberInitial.push(singleSelectorParts[i])
        }
        i += 1;
    }

    return { 
        columnName: columnInitial.join(''), 
        originalSelector: singleSelector, 
        numberPart: numberInitial.join('')
    };
}


function getColumnInitialMulti(multiSelector) {
    let selectorPart = multiSelector;
    let splitParts = selectorPart.split('_');

    const lettersObject = generateLetters(splitParts[0], splitParts[1]);
    // console.log(lettersObject);

    return {
        letters: lettersObject.generatedLetters, 
        originalLetters: lettersObject.originalLetters
    };
}


function columnCountFromSelection(selectorParts) {
    let selectorColumns = new Map();
    const columnsToBeDeleted = [];
    let columnsToKeep = 0;

    selectorParts.forEach(part => {
        part = part.trim();
        // console.log(part);
        
        if(part.startsWith('-')) {
            if(part.includes('_')) {
                const {letters} = getColumnInitialMulti(part.slice(1));
                columnsToBeDeleted.push(...letters);
            } else {
                const {columnName} = getColumnInitial(part.slice(1));
                columnsToBeDeleted.push(columnName);
            }
        } else if(part.includes('_')) {
            const {letters, originalLetters} = getColumnInitialMulti(part);
            columnsToKeep += letters.length;

        } else {
            let {columnName, originalSelector} = getColumnInitial(part);
            columnsToKeep += 1;

        }
    });

    return columnsToKeep - columnsToBeDeleted.length;
}


function columnSelectorParser(selectorParts, ws) {
    let selectorColumns = new Map();
    const columnsToBeDeleted = [];

    selectorParts.forEach(part => {
        part = part.trim();
        // console.log(part);
        
        if(part.startsWith('-')) {
            if(part.includes('_')) {
                const {letters} = getColumnInitialMulti(part.slice(1));
                columnsToBeDeleted.push(...letters);
            } else {
                const {columnName} = getColumnInitial(part.slice(1));
                columnsToBeDeleted.push(columnName);
            }
        } else if(part.includes('_')) {
            const {letters, originalLetters} = getColumnInitialMulti(part);

            originalLetters.forEach((originalLetter, index) => {
                const name = ws[originalLetter].w;
                selectorColumns.set(letters[index], name);
            });
        } else {
            let {columnName, originalSelector} = getColumnInitial(part);

            const name = ws[originalSelector].w;
            selectorColumns.set(columnName, name);
        }
    });

    // console.log(columnsToBeDeleted);
    for (const columnToDelete of [...new Set(columnsToBeDeleted)]) {
        delete selectorColumns.delete(columnToDelete);
    }

    return selectorColumns;
}


function getNumbersRange(numbersList) {
    if (numbersList.length > 0) {
        numbersList.sort((a,b) => a-b);
        const length = numbersList.length;
    
        const sum = numbersList.reduce((acc, curr) => acc + curr, 0);
        const avg = sum / numbersList.length;
    
        const median = length % 2 === 1 ?  numbersList[(length / 2) -.5 + 1] : ( numbersList[length / 2] + numbersList[length / 2 + 1]) / 2;
        return {min: numbersList[0], max: numbersList[length - 1], median: Number.parseFloat(median).toFixed(2), 
            uniqueCount: new Set(numbersList).size, sum:sum, avg:Number.parseFloat(avg).toFixed(2)};
    } else {
        return {min: '--', max: '--', median: '--', uniqueCount: '--', sum:'--', avg:'--'};
    }
    
}


// All of selectorColumns, yAxisSelectorColumns, viewByColumns, unitClickColumns are Maps
function readDataFromExcel(ws, rowsArray = [], selectorColumns, yAxisSelectorColumns, viewByColumns, unitClickColumns) {
    const dataParse = [];

    const viewByMap = new Map();

    const hasSelectorColumns = selectorColumns && selectorColumns.size > 0;
    const hasUnitClickColumns = unitClickColumns && unitClickColumns.size > 0;
    const hasYAxisSelectorColumns = yAxisSelectorColumns && yAxisSelectorColumns.size > 0;
    const hasViewByColumns = viewByColumns && viewByColumns.size > 0;

    if (hasViewByColumns) {
        // Read the viewBy field - and create a list of distinct values over all the rows
        for (const value of viewByColumns.values()) {
            viewByMap.set(value, new Set());
        }
    }

    for (const rowNumber of rowsArray) {
        const dataRow = new Map();

        if (hasSelectorColumns) {
            for (const columnKey of selectorColumns.keys()) {
                const cellToRead = columnKey + rowNumber;
                const data = ws[cellToRead]?.w || null;
                dataRow.set(selectorColumns.get(columnKey), data);
            }
        }
        
        if (hasViewByColumns) {
            for (const columnKey of viewByColumns.keys()) {
                const cellToRead = columnKey + rowNumber;
                const data = ws[cellToRead]?.w || null;
                dataRow.set(viewByColumns.get(columnKey), data);

                if (data) {
                    viewByMap.get(viewByColumns.get(columnKey)).add(data);
                }
            }
        }

        if (hasUnitClickColumns) {
            for (const columnKey of unitClickColumns.keys()) {
                const cellToRead = columnKey + rowNumber;
                const data = ws[cellToRead]?.w || null;
                dataRow.set(unitClickColumns.get(columnKey), data);
            }
        }

        if (hasYAxisSelectorColumns) {
            for (const columnKey of yAxisSelectorColumns.keys()) {
                const cellToRead = columnKey + rowNumber;
                const data = ws[cellToRead]?.w || null;
                dataRow.set(yAxisSelectorColumns.get(columnKey), data);
            }
        }
        
        // Check for all values null - Removing this, since now we are checking very specific rows and columns, which might be empty
        dataParse.push(dataRow);
        // if (Object.values(dataRow).filter(value => value !== null).length > 0) {
        //     dataParse.push(dataRow);
        // }
    }

    const viewByOptions = [];
    for(const [viewByKey, viewByValues] of viewByMap.entries()) {
        viewByOptions.push({
            label: viewByKey,
            values: [...viewByValues].map(val => {
                return {label: val, value: val};
            })
        })
    }

    return { dataParse, viewByOptions, showViewBy: hasViewByColumns };
}


function readDataFromTable(tableData, rowsArray, selectorColumns, yAxisSelectorColumns, viewByColumns, unitClickColumns) {
    const dataParse = [];

    const viewByMap = new Map();

    const hasSelectorColumns = selectorColumns && selectorColumns.size > 0;
    const hasUnitClickColumns = unitClickColumns && unitClickColumns.size > 0;
    const hasYAxisSelectorColumns = yAxisSelectorColumns && yAxisSelectorColumns.size > 0;
    const hasViewByColumns = viewByColumns && viewByColumns.size > 0;

    if (hasViewByColumns) {
        // Read the viewBy field - and create a list of distinct values over all the rows
        for (const value of viewByColumns.values()) {
            viewByMap.set(value, new Set());
        }
    }

    for (const rowNumber of rowsArray) {
        const tableRow = tableData.rows[rowNumber - 1];  // Rows holds the information, and the rowNumber starts from 1, but array indexing starts from 0
        if (tableRow) {
            const dataRow = new Map();
            if (hasSelectorColumns) {
                for (const columnKey of selectorColumns.keys()) {
                    const data = tableRow[columnKey] || null;
                    dataRow.set(selectorColumns.get(columnKey), data);
                }
            }
            
            if (hasViewByColumns) {
                for (const columnKey of viewByColumns.keys()) {
                    const data = tableRow[columnKey] || null;
                    dataRow.set(viewByColumns.get(columnKey), data);

                    if (data) {
                        viewByMap.get(viewByColumns.get(columnKey)).add(data);
                    }
                }
            }

            if (hasUnitClickColumns) {
                for (const columnKey of unitClickColumns.keys()) {
                    const data = tableRow[columnKey] || null;
                    dataRow.set(unitClickColumns.get(columnKey), data);
                }
            }

            if (hasYAxisSelectorColumns) {
                for (const columnKey of yAxisSelectorColumns.keys()) {
                    const data = tableRow[columnKey] || null;
                    dataRow.set(yAxisSelectorColumns.get(columnKey), data);
                }
            }
            
            // Check for all values null - Removing this, since now we are checking very specific rows and columns, which might be empty
            dataParse.push(dataRow);
        }
    }

    const viewByOptions = [];
    for(const [viewByKey, viewByValues] of viewByMap.entries()) {
        viewByOptions.push({
            label: viewByKey,
            values: [...viewByValues].map(val => {
                return {label: val, value: val};
            })
        })
    }

    return { dataParse, viewByOptions, showViewBy: hasViewByColumns };
}


function readDataFromRawData(parsedData, rowsArray, selectorColumns, yAxisSelectorColumns, viewByColumns, unitClickColumns) {
    const dataParse = [];

    const viewByMap = new Map();

    const hasSelectorColumns = selectorColumns && selectorColumns.size > 0;
    const hasUnitClickColumns = unitClickColumns && unitClickColumns.size > 0;
    const hasYAxisSelectorColumns = yAxisSelectorColumns && yAxisSelectorColumns.size > 0;
    const hasViewByColumns = viewByColumns && viewByColumns.size > 0;

    if (hasViewByColumns) {
        // Read the viewBy field - and create a list of distinct values over all the rows
        for (const value of viewByColumns.values()) {
            viewByMap.set(value, new Set());
        }
    }

    for (const rowNumber of rowsArray) {
        const row = parsedData[rowNumber - 1];
        if (row) {
            const dataRow = new Map();
            if (hasSelectorColumns) {
                for (const columnKey of selectorColumns.keys()) {
                    const data = row[columnKey] || null;
                    dataRow.set(selectorColumns.get(columnKey), data);
                }
            }
            
            if (hasViewByColumns) {
                for (const columnKey of viewByColumns.keys()) {
                    const data = row[columnKey] || null;
                    dataRow.set(viewByColumns.get(columnKey), data);

                    if (data) {
                        viewByMap.get(viewByColumns.get(columnKey)).add(data);
                    }
                }
            }

            if (hasUnitClickColumns) {
                for (const columnKey of unitClickColumns.keys()) {
                    const data = row[columnKey] || null;
                    dataRow.set(unitClickColumns.get(columnKey), data);
                }
            }

            if (hasYAxisSelectorColumns) {
                for (const columnKey of yAxisSelectorColumns.keys()) {
                    const data = row[columnKey] || null;
                    dataRow.set(yAxisSelectorColumns.get(columnKey), data);
                }
            }
            
            // Check for all values null - Removing this, since now we are checking very specific rows and columns, which might be empty
            dataParse.push(dataRow);
        }
    }

    const viewByOptions = [];
    for(const [viewByKey, viewByValues] of viewByMap.entries()) {
        viewByOptions.push({
            label: viewByKey,
            values: [...viewByValues].map(val => {
                return {label: val, value: val};
            })
        })
    }

    return { dataParse, viewByOptions, showViewBy: hasViewByColumns };
}


// valuesToSelect identifies values that would be combined together and those that would be separate. This might also hold numerical ranges. 
// Later - the user can click Read this column - if dataType === category -> and show all the distinct values, that the user can then select - show a small query builder on pop up.
function createValuesToSelectObject(valuesToSelect, caseSensitive) {
    // Format - [P] as Present, [A,Leave] as Absent  OR  [P] [A,Leave] OR [V1,V2] as "First Half" , [V3,V4] as "Second Half"
    const valuesToRead = {
        specificValues: []
    };
    const combineArrays = {};
    const specificValues = [];
    const displayChange = {};

    // First lets break it into tokens
    const tokens = valuesToSelect.split("[").map(token => "[" + token).splice(1);
    // ["[P] as Present, ", "[A,Leave] as Absent"]
    /** OR
     * Tokens - 
     * 0: "[V1,V2] as \"First Half\" , "
       1: "[V3,V4] as \"Second Half\""
     */
    tokens.forEach(token => {
        token = token.trim();
        // const tokenParts = token.trim().split(" "); // stupid
        // ["[P]", "as", "Present,", ""], where tokenParts[0] has the specificValues and the token after as ( lowercase ) is what is should show as
        // const specificValuesEntered = tokenParts[0].substring(1,  tokenParts[0].length - 1);
        // Instead find the start and the end bracktes and everything within that is specificValuesEntered
        const specificValuesEntered = token.substring(1, token.indexOf("]"));
        let specificValuesArr = specificValuesEntered.split(",").map(member => member.trim());
        if (!caseSensitive) {
            specificValuesArr = specificValuesArr.map(member => member.toLocaleLowerCase());
        }

        specificValues.push(specificValuesArr);
        combineArrays[specificValuesArr[0]] = specificValuesArr;

        // Now check for presence of "as"
        const asIndex = token.indexOf('as');
        if (asIndex > -1) {
            const displayNameIndex = asIndex + 3;
            displayChange[specificValuesArr[0]] = token.substring(displayNameIndex).replaceAll(/"/ig, '').split(",")[0].trim();
        }
    });

    valuesToRead.specificValues = specificValues.flat();
    valuesToRead.combineArrays = combineArrays;
    valuesToRead.displayChange = displayChange;

    return valuesToRead;

    // Previous Code
    // valuesToRead.specificValues = valuesToSelect.split(" ")
    //     .map(sepValue => {
    //         let membersOfAGrp = sepValue.split(",");
    //         if (!caseSensitive) {
    //             membersOfAGrp = membersOfAGrp.map(member => member.toLocaleLowerCase()) 
    //         }
    //         combineArrays[membersOfAGrp[0]] = membersOfAGrp;
    //         return membersOfAGrp;
    //     }).flat();
    // valuesToRead.combineArrays = combineArrays;
    // return valuesToRead;

    // Current values
    /**
     * With the selections as P A,Leave
     * {
            "specificValues": ["p","a","leave"],
            "combineArrays": {
                "p": ["p"],
                "a": ["a","leave"]
            }
        }
     */
}


function interpretQuestionPropertiesExcel(chartType, selections, ws) {
    const xAxisDetails = selections.xAxis;
    const xAxisSelectorsArr = xAxisDetails?.selector.split(',') || [];
    const xAxisSelectorsMap = columnSelectorParser(xAxisSelectorsArr, ws);

    const mappingType = selections.mappingType;

    let secondColumnSelectorsMap = new Map();
    let secondColumns = [];
    if (mappingType === TEXT.SINGLE_COLUMN_SINGLE_COLUMN 
        || mappingType === TEXT.SINGLE_COLUMN_MULTIPLE_COLUMNS) {
        const secondColumnDetails = selections.secondColumn;
        const secondColumnSelectorsArr = secondColumnDetails.selector.split(',');
        secondColumnSelectorsMap = columnSelectorParser(secondColumnSelectorsArr, ws);
        secondColumns =  Array.from(secondColumnSelectorsMap.values());
    }

    // Read the Row values
    const extentDetails = selections.extent;
    let rowsArray = [];
    if (extentDetails) {
        rowsArray = rowSelectorParser(extentDetails.selector);
    }


    /**************Additionals *************/
    const additionals = selections.additionals || {};

    // If there is a view By, that should become the part of the data read from the excel;
    // But there could be more than one viewBy
    let viewByColumnsMap = new Map();
    if (additionals.viewBy && additionals.viewBy !== '') {
        const viewByColumnsArr = additionals.viewBy.split(',');
        viewByColumnsMap = columnSelectorParser(viewByColumnsArr, ws);
    }

    // If there are other related columns that need to be shown - those will also need to be added to the parsed data as viewBy
    // These can be in any format.
    let unitClickColumnsMap = new Map();
    const unitClickRelatedColumns = additionals.unitClickRelatedColumns;
    if (((additionals.unitClick && additionals.unitClick !== TEXT.NOTHING) || chartType === TEXT.CHART_TABLE)
        && unitClickRelatedColumns && unitClickRelatedColumns !== '') {
        const unitClickColumnArr = unitClickRelatedColumns.split(',');
        unitClickColumnsMap = columnSelectorParser(unitClickColumnArr, ws);
    }

    const { dataParse, viewByOptions, showViewBy } = 
        readDataFromExcel(ws, rowsArray, xAxisSelectorsMap, secondColumnSelectorsMap, viewByColumnsMap, unitClickColumnsMap);
    const unitClickFields = Array.from(unitClickColumnsMap.values());

    return {
        selectorColumns : xAxisSelectorsMap, rowsArray, rawData: dataParse, viewByOptions, showViewBy, unitClickFields, yAxisColumns : secondColumns
    }
}


function getColumnId(headers, fieldName) {
    return (headers.find(header => header.label === fieldName) || {}).id;
}


function settingTableSelectorMaps(arr, headers) {
    const map = new Map();
    arr = arr.map(item => item.trim());
    arr.forEach(columnName => {
        map.set(getColumnId(headers, columnName), columnName);
    });

    return map;
}


function interpretQuestionPropertiesTable(chartType, selections, data) {
    const xAxisDetails = selections.xAxis;

    const xAxisSelectorsArr = xAxisDetails?.selector?.map(selectedItem => selectedItem.label);
    const xAxisSelectorsMap = settingTableSelectorMaps(xAxisSelectorsArr || [], data.headers);

    const mappingType = selections.mappingType;

    let secondColumnSelectorsMap = new Map();
    let secondColumns = [];
    if (mappingType === TEXT.SINGLE_COLUMN_SINGLE_COLUMN 
        || mappingType === TEXT.SINGLE_COLUMN_MULTIPLE_COLUMNS) {
        // Then take the column from here and also add them to the selector columns, but also keep a record of which column goes into X-axis fields and which go into Y-axis fields
        const secondColumnDetails = selections.secondColumn;
        const secondColumnSelectorsArr = secondColumnDetails.selector.map(selectedItem => selectedItem.label);
        secondColumnSelectorsMap = settingTableSelectorMaps(secondColumnSelectorsArr, data.headers);
    
        secondColumns =  Array.from(secondColumnSelectorsMap.values());
    }

    // Read the Row values
    let rowsArray = [];

    const extentDetails = selections.extent;
    if (extentDetails) {
        rowsArray = rowSelectorParser(extentDetails.selector);
    } else {
        rowsArray = [...Array(data.rows.length).keys()].map(key=> key += 1);
    }
    
    /**************Additionals *************/
    const additionals = selections.additionals || {};

    // If there is a view By, that should become the part of the data read from the table;
    // But there could be more than one viewBy
    let viewByColumnsMap = new Map();
    if (additionals.viewBy && additionals.viewBy !== '') {
        const viewByColumnsArr = additionals.viewBy.map(selectedItem => selectedItem.label);
        viewByColumnsMap = settingTableSelectorMaps(viewByColumnsArr, data.headers);
    }

    // If there are other related columns that need to be shown - those will also need to be added to the parsed data as viewBy
    // These can be in any format.
    let unitClickColumnsMap = new Map();
    const unitClickRelatedColumns = additionals.unitClickRelatedColumns;
    if (((additionals.unitClick && additionals.unitClick !== TEXT.NOTHING) || chartType === TEXT.CHART_TABLE)
        && unitClickRelatedColumns && unitClickRelatedColumns !== '') {
        const unitClickColumnArr = unitClickRelatedColumns.map(selectedItem => selectedItem.label);
        unitClickColumnsMap = settingTableSelectorMaps(unitClickColumnArr, data.headers);
    }

    const { dataParse, viewByOptions, showViewBy } = 
        readDataFromTable(data, rowsArray, xAxisSelectorsMap, secondColumnSelectorsMap, viewByColumnsMap, unitClickColumnsMap);
    const unitClickFields = Array.from(unitClickColumnsMap.values());

    return {
        selectorColumns : xAxisSelectorsMap, rowsArray, rawData: dataParse, viewByOptions, showViewBy, unitClickFields, yAxisColumns : secondColumns
    }
}


/************************  Raw Data  ****************************/
function settingRawDataSelectorMaps(selection) {
    const selectorsArr = selection.map(selectedItem => selectedItem.label);
    const selectorsMap = new Map();
    selectorsArr.forEach(columnName => {
        selectorsMap.set(columnName, columnName);
    });

    return selectorsMap;
}

function interpretQuestionPropertiesRawData(chartType, selections, data) {
    const xAxisDetails = selections.xAxis;

    const parsedData = JSON.parse(data.value);
    const rows = data.rows;

    const xAxisSelectorsMap = settingRawDataSelectorMaps(xAxisDetails?.selector);
    const mappingType = selections.mappingType;

    let secondColumnSelectorsMap = new Map();
    let secondColumns = [];
    if (mappingType === TEXT.SINGLE_COLUMN_SINGLE_COLUMN 
        || mappingType === TEXT.SINGLE_COLUMN_MULTIPLE_COLUMNS) {
        // Then take the column from here and also add them to the selector columns, but also keep a record of which column goes into X-axis fields and which go into Y-axis fields
        const secondColumnDetails = selections.secondColumn;
        secondColumnSelectorsMap = settingRawDataSelectorMaps(secondColumnDetails.selector);
    
        secondColumns =  Array.from(secondColumnSelectorsMap.values());
    }


    // Read the Row values
    let rowsArray = [];

    const extentDetails = selections.extent;
    if (extentDetails) {
        rowsArray = rowSelectorParser(extentDetails.selector);
    } else {
        rowsArray = [...Array(rows).keys()].map(key=> key += 1);
    }
    
    /**************Additionals *************/
    const additionals = selections.additionals || {};

    // If there is a view By, that should become the part of the data read from the table;
    // But there could be more than one viewBy
    let viewByColumnsMap = new Map();
    if (additionals.viewBy && additionals.viewBy !== '') {
        viewByColumnsMap = settingRawDataSelectorMaps(additionals.viewBy);
    }

    // If there are other related columns that need to be shown - those will also need to be added to the parsed data as viewBy
    // These can be in any format.
    let unitClickColumnsMap = new Map();
    const unitClickRelatedColumns = additionals.unitClickRelatedColumns;
    if (((additionals.unitClick && additionals.unitClick !== TEXT.NOTHING) || chartType === TEXT.CHART_TABLE)
        && unitClickRelatedColumns && unitClickRelatedColumns !== '') {
        const unitClickColumnArr = unitClickRelatedColumns.split(',');
        unitClickColumnsMap = settingRawDataSelectorMaps(unitClickRelatedColumns);
    }

    const { dataParse, viewByOptions, showViewBy } = 
        readDataFromRawData(parsedData, rowsArray, xAxisSelectorsMap, secondColumnSelectorsMap, viewByColumnsMap, unitClickColumnsMap);
    const unitClickFields = Array.from(unitClickColumnsMap.values());

    return {
        selectorColumns : xAxisSelectorsMap, rowsArray, rawData: dataParse, viewByOptions, showViewBy, unitClickFields, yAxisColumns : secondColumns
    }
}


function generateNewWorkspace(workspaceName) {
    return {
        name: workspaceName ?? `New Workspace - ${randomIdGenerator(16)}`,
        id : randomIdGenerator(16),
        questions: [],
        datasources: [],
        dashboards: [],
        deleteAllowed: true
    }
}

/****************************** Quick To Workspace *****************************/
function quickToDatasourceTableData(xAxis, yAxis, data, datasourceName) {
    const idLength = 4;

    const headers = [];
    const columns = [xAxis, ...yAxis];
    for (let i = 0; i < columns.length; i++) {
        headers.push({id: randomIdGenerator(idLength), label: columns[i]});
    }

    const rows = [];
    for (let i = 0; i < data.length; i++) {
        const rowData = {};
        rowData.id = randomIdGenerator(idLength);
        for(let j=1; j < headers.length; j++) {
            rowData[headers[j].id] = data[j][headers[j].label];
        }

        rows.push(rowData);
    }
    
    const objectToAdd = {
        id : randomIdGenerator(8), data: {headers, rows}, name: datasourceName, 
            type: 'table', availableFields: headers || []
    };

    return objectToAdd;
}


function quickToDatasourceRawData(xAxis, yAxis, data, datasourceName) {
    const columns = [xAxis, ...yAxis];
    const availableFields = columns.map(column => {
        return {isChecked: true, label: column}
    });

    const details = {
        dataFormat: 'json',
        error: '',
        rows: data.length,
        value: JSON.stringify(data)
    }

    const objectToAdd = {
        id : randomIdGenerator(8), data: details, name: datasourceName, 
            type: TEXT.BULK_DATASOURCE, availableFields: availableFields || []
    };

    return objectToAdd;
}


function quickChartToQuestion(questionText, chartType, xAxis, yAxis, datasourceId, datasourceName, type) {
    const chartDetails = getChartType(chartType);
    const question = {
        id: randomIdGenerator(8),
        text: questionText,
        chartType: chartType,
        showInDashboard: false,
        chartWidth: chartDetails.width,
        chartHeight: chartDetails.height
    };

    const xAxisSelection = {
        valueInterpreter: TEXT.ALL,
        caseSensitive: false,
        selector: [{
            id: randomIdGenerator(4),
            label: xAxis,
            value: xAxis
        }],
        dataType: TEXT.CATEGORY_DATA_TYPE
    };

    const secondColumnSelectors = yAxis.map(column => {
        return {
            id: randomIdGenerator(4),
            label: column,
            value: column
        }
    });
    const secondColumn = {
        valueInterpreter: TEXT.ALL,
        caseSensitive: false,
        dataType: TEXT.NUMBER_DATA_TYPE,
        selector: secondColumnSelectors,
        numberValues: {
            valueGroups: [],
            mainOperator: {
                label: 'Sum',
                value: 'sum'
            },
            valueLevel: TEXT.ALL,
            missingValueHandler: 'zero'
        }
    }

    const properties = {
        mappingType: yAxis.length > 1 ? TEXT.SINGLE_COLUMN_MULTIPLE_COLUMNS : TEXT.SINGLE_COLUMN_SINGLE_COLUMN,
        xAxis: xAxisSelection,
        secondColumn
    }

    const datasource = {
        label: `${datasourceName} | ${type}`,
        type: type,
        value: datasourceId
    }

    question.properties = properties;
    question.datasource = datasource;

    return question;
}
/***********************************************************************/

function randomIdGenerator(length = 6) {
    const set = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let id = [];
    for (let i = 0 ; i < length; i ++) {
        id.push(set[Math.floor(Math.random() * set.length)]);
    }
    return id.join("");
}


const sizeInMb = (value) => {
    let valueInKb = value / 1024;
    return valueInKb > 1024 ? Math.floor(valueInKb / 1024) + ' Mb' : Math.round(valueInKb) + ' Kb';
}

const getMappingType = (value) => {
    const options = {
        [TEXT.MULTIPLE_COLUMNS_MULTIPLE_ROWS] : 'Multiple Columns, Multiple Rows',
        [TEXT.SINGLE_COLUMN_MULTIPLE_ROWS] : 'Single Column, Multiple Rows',
        [TEXT.SINGLE_COLUMN_SINGLE_COLUMN] : 'Single Column, Single Column',
        [TEXT.SINGLE_COLUMN_MULTIPLE_COLUMNS] : 'Single Column, Multiple Columns'
    }

    return options[value];
}


function formatName(text, format) {
    if (text) {
        const nameParts = text.split(" ").filter(part => part.length > 0);
        switch(format) {
            case 'LNI': // Last name with initials
                const initials = nameParts.slice(0, nameParts.length - 1).map(part => part.charAt(0).toLocaleUpperCase());
                return initials.join(". ") + ". " + nameParts[nameParts.length - 1];
            case 'FNO': // First Name only
                return text.split(" ")[0];
            case 'IO': // Initials only
                return nameParts.map(part => part.charAt(0).toLocaleUpperCase()).join(". ");
            case 'FULL': // Full Name
            default:
                return text;
        }
    } else {
        return text;
    }
}

const getChartType = (type) => {
    return vizTypes[type];
}


const getChartOptions = () => {
    return Object.values(vizTypes);
}


function getYAxisDataTypeAndValuesRead(mappingType, secondColumnDetails) {
    let valuesToRead = {};
    let yAxisDataType = '';
    if (mappingType === TEXT.SINGLE_COLUMN_SINGLE_COLUMN 
        || mappingType === TEXT.SINGLE_COLUMN_MULTIPLE_COLUMNS) {
        yAxisDataType = secondColumnDetails.dataType; 
        if (yAxisDataType === TEXT.CATEGORY_DATA_TYPE) {
            if (secondColumnDetails.categoryValues && secondColumnDetails.categoryValues !== '') {
                valuesToRead =  createValuesToSelectObject(secondColumnDetails.categoryValues, secondColumnDetails.caseSensitive);
            }
        } else {
            valuesToRead = secondColumnDetails.numberValues;
        }
    }

    return {valuesToRead, yAxisDataType};
}


// Check Functions
function checkHex(hex) {
    const hexRegex = /^[#]*([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i
    return hexRegex.test(hex);
}

// This takes colors in the format rgb(.., .. ,..) - which is a string
function generateInterpolatedColors(color1, color2, steps) {
    const stepFactor = (steps > 1) ? (1 / (steps - 1)) : 1;
    const interpolatedColorArray = [];
    if (checkHex(color1)) {
      color1 = hexTorgb(color1)
    }
    if (checkHex(color2)) {
      color2 = hexTorgb(color2)
    }
    const color1Array = color1.match(/\d+/g).map(Number);
    const color2Array = color2.match(/\d+/g).map(Number);
    for (let i = 0; i < steps; i++) {
      const interpolatedColor = interpolateColor(color1Array, color2Array, stepFactor * i);
      const rgbaConversion = `rgb(${interpolatedColor[0]},${interpolatedColor[1]},${interpolatedColor[2]})`;
      interpolatedColorArray.push(rgbaConversion);
    }
    return interpolatedColorArray;
}

function hexTorgb(hex) {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgb(${r},${g},${b})`;
}

function interpolateColor(color1, color2, amount) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + amount * (color2[i] - color1[i]));
    }
    return result;
}


function fullColorHex(baseColor) {
    if (!checkHex(baseColor)) {
        const baseColorArray = baseColor.match(/\d+/g)?.map(Number);
        return '#' +
          rgbToHex(baseColorArray[0]) + rgbToHex(baseColorArray[1]) + rgbToHex(baseColorArray[2]);
    }
}

function rgbToHex(rgb) {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
}


const availableChartOptions = {
    [TEXT.QUICK_CHART_LINE]: ['showLabels', 'handleNulls', 'colorOptions', 'lineType'],
    [TEXT.QUICK_CHART_AREA]: ['showLabels', 'handleNulls', 'colorOptions', 'stackUp'],
    [TEXT.QUICK_CHART_VERTICAL]: ['showLabels', 'barLabelPosition', 'barLabelRotation', 'colorType', 'colorOptions', 'percentColorChooser', 'breakpoints', 'stackUp', 'showBarLabel'],
    [TEXT.QUICK_CHART_HORIZONTAL]: ['showLabels', 'barLabelPosition', 'colorType', 'colorOptions', 'percentColorChooser', 'breakpoints', 'stackUp', 'showBarLabel'],
    [TEXT.QUICK_CHART_PIE]: ['showLines', 'colorType', 'colorOptions', 'percentColorChooser', 'breakpoints', 'percentBasedValues'],
    [TEXT.QUICK_CHART_DONUT]: ['showLines', 'colorType', 'colorOptions', 'percentColorChooser', 'breakpoints', 'percentBasedValues'],
    [TEXT.QUICK_CHART_SCATTER]: ['showLabels', 'regressionLine', 'colorType', 'colorOptions', 'percentColorChooser', 'breakpoints'],
}

function getApplicableOptions(chartType) {
    return availableChartOptions[chartType];
}

function getDefaultColors(index) {
    return ["#1f77b4", "#8c564b", "#bcbd22", "#e377c2", "#d62728", "#2ca02c", "#ff7f0e", "#9467bd", "#7f7f7f", "#17becf"][index];
}


const chartProviderLabels = {
    [TEXT.CHART_PROVIDER_CUSTOM]: 'Chart Awesome',
    [TEXT.CHART_PROVIDER_RECHARTS]: 'Recharts',
    [TEXT.CHART_PROVIDER_VISX]: 'VisX'
};

const chartProviderMapping = {
    [TEXT.QUICK_CHART_LINE]: {
        label: 'Line Chart',
        providers: [TEXT.CHART_PROVIDER_CUSTOM, TEXT.CHART_PROVIDER_RECHARTS, TEXT.CHART_PROVIDER_VISX]
    }, 
    [TEXT.QUICK_CHART_AREA]: {
        label: 'Area Chart',
        providers: [TEXT.CHART_PROVIDER_CUSTOM, TEXT.CHART_PROVIDER_RECHARTS, TEXT.CHART_PROVIDER_VISX]
    },
    [TEXT.QUICK_CHART_PIE]: {
        label: 'Pie Chart',
        providers: [TEXT.CHART_PROVIDER_CUSTOM, TEXT.CHART_PROVIDER_RECHARTS]
    },
    [TEXT.QUICK_CHART_DONUT]: {
        label: 'Donut Chart',
        providers: [TEXT.CHART_PROVIDER_RECHARTS]
    },
    [TEXT.QUICK_CHART_VERTICAL]: {
        label: 'Vertical Bar Chart',
        providers: [TEXT.CHART_PROVIDER_CUSTOM, TEXT.CHART_PROVIDER_RECHARTS, TEXT.CHART_PROVIDER_VISX]
    },
    [TEXT.QUICK_CHART_HORIZONTAL]: {
        label: 'Horizontal Bar Chart',
        providers: [TEXT.CHART_PROVIDER_CUSTOM, TEXT.CHART_PROVIDER_RECHARTS, TEXT.CHART_PROVIDER_VISX]
    },
    [TEXT.CHART_TABLE]: {
        label: 'Table',
        providers: [TEXT.CHART_PROVIDER_CUSTOM]
    },
    [TEXT.HIERARCHY]: {
        label: 'Hierarchy',
        providers: [TEXT.CHART_PROVIDER_CUSTOM]
    }
}

function getAllChartMappingOptions() {
    return chartProviderMapping;
}

function getChartProviderLabels() {
    return chartProviderLabels;
}

function getChartProviders(chartType) {
    return chartProviderMapping[chartType]?.providers || [];
}


function getDefaultValuesForChartSettings() {
    return {
        showLabels: true,
        showLines: true,
        percentBasedValues: false,
        lineType: 'solid',
        stackUp: false,
        colorType: 'single',
        handleNulls: false,
        showBarLabel: true,
        barLabelPosition: 'outside',
        barLabelRotation: '0',
        regressionLine: false,
        regressionLineChoice: '#82ca9d',
        breakpoints: [],
        colorOptions: {},
        percentColorChooser: []
    }
}


// Check if the value is a primitive or array of primitive
function checkPrimitive(value) {
  const typesAllowed = ['string', 'number', 'boolean'];

  const isAPrimitive = typesAllowed.includes(typeof value);
  const isArrayOfPrimitives = Array.isArray(value) && value.every((item) => typesAllowed.includes(typeof item));

  return isAPrimitive || isArrayOfPrimitives;
}

function flattenObject(obj, baseKey, tableViewRow) {
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (checkPrimitive(value)) {
      const newKey = baseKey !== '' ? `${baseKey}.${key}` : key;
      tableViewRow[newKey] = value;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      // Not a primtive, But an object.
      flattenObject(value, key, tableViewRow);
    } else {
        // This is probably an array of objects. Simply indicate this
        const newKey = baseKey !== '' ? `${baseKey}.${key}` : key;
        // Do not add this
        // tableViewRow[newKey] = 'Is an Array with more objects.';
        // if (!allOtherArrays.includes(newKey)) {
        //   allOtherArrays.push(newKey);
        // }
    }
  }
}

function getSelectStyles() {
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          padding: 8
        })
    }

    return customStyles;
}


const saveTemplateAsFile = (filename, jsonToWrite, fileType, callback) => {
    const blob = new Blob([jsonToWrite], { type: fileType === 'json' ? 'text/json' : 'plain' });
    const link = document.createElement("a");

    link.download = `${filename}.${fileType}`;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = [fileType === 'json' ? 'text/json' : 'plain', link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove();

    callback('success');
};




export {
    rowSelectorParser,
    columnCountFromSelection,
    columnSelectorParser,
    getNumbersRange,
    readDataFromExcel,
    readDataFromTable,
    readDataFromRawData,
    settingTableSelectorMaps,
    interpretQuestionPropertiesExcel,
    interpretQuestionPropertiesTable,
    interpretQuestionPropertiesRawData,
    randomIdGenerator,
    generateInterpolatedColors,
    formatName,
    fullColorHex,
    sizeInMb,
    getMappingType,
    getChartType,
    createValuesToSelectObject,
    getYAxisDataTypeAndValuesRead,
    getChartOptions,
    getApplicableOptions,
    getDefaultColors,
    getDefaultValuesForChartSettings,
    flattenObject,
    checkPrimitive,
    generateNewWorkspace,
    quickToDatasourceTableData,
    quickToDatasourceRawData,
    quickChartToQuestion,
    getSelectStyles,
    getAllChartMappingOptions,
    getChartProviderLabels,
    getChartProviders,
    saveTemplateAsFile
};