//@ts-check

import FullSizeLoader from "../shared/FullSizeLoader";
import { useState } from "react";

const FeedbackForm = ({loaded}) => {
    return (
        <>
            <iframe className="airtable-embed airtable-dynamic-height" onLoad={e => {window.parent.scrollTo(0,0); loaded()}}
                src="https://airtable.com/embed/shrRlytbQH09gVdMk?backgroundColor=gray" 
                frameBorder="0" width="100%" height="1300" style={{background: 'transparent'}} ></iframe>
        </>
    )
}


export default function Feedback() {
    const [activeTab, setActiveTab] = useState('feedback');
    const [isLoading, setIsLoading] = useState(true);

    function changeActiveTab(tab) {
        setActiveTab(tab);
        setIsLoading(true);
    }

    return (
        <section className="mb-6">
            <hr/>
            <FeedbackForm loaded={_ => setIsLoading(false)}></FeedbackForm>
            {
                isLoading && <FullSizeLoader />
            }
        </section>
        
    )
}




